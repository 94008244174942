import React from "react";
import {
  Line,
  Area,
  XAxis,
  ComposedChart,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts";
import { formatAsCHF } from "../../../../../utils/helpers";
import chartComponents from "./charts/chartComponents";
import Color from "color";
import { Fragment } from "react/cjs/react.production.min";
import { useTranslation } from "react-i18next";

export default function Chart({ data, events, type, cause }) {
  const { t } = useTranslation();
  const brandColor = Color("#6647FF");
  const chartComponentsOfType = chartComponents[type];
  const { areas, lines } = cause
    ? chartComponentsOfType[cause]
    : chartComponentsOfType;

  // leave this in case of discovering issues with data
  const areasWithValues = [];
  const removeZeroValues = (object) =>
    Object.keys(object).reduce((acc, key) => {
      if (object[key] !== 0) {
        acc[key] = object[key];
        // Also collect all areas that will be shown on the chart:
        if (!areasWithValues.includes(key)) {
          areasWithValues.push(key);
        }
      }
      return acc;
    }, {});

  data.map((month) => removeZeroValues(month));

  return (
    <>
      <ResponsiveContainer
        width="100%"
        height="100%"
        className="hidden md:block"
      >
        <ComposedChart
          data={data}
          margin={{
            top: 80,
            right: 60,
            bottom: 20,
            left: 50,
          }}
        >
          <XAxis dataKey={type === "age" ? "year" : "month"} />
          <YAxis tickFormatter={(value) => formatAsCHF(value)} />
          <Tooltip
            label="month"
            content={({ active, payload, label }) => {
              if (!active || !payload) return null;
              return (
                <div className="bg-white rounded shadow p-2 flex flex-col space-y-2">
                  <p className="font-bold">{label}</p>
                  {payload
                    .filter((payload) => payload.value > 0)
                    .reverse() // Match the order of the areas
                    .map((payload, index) => (
                      <div
                        key={payload.name}
                        className="flex justify-between items-center space-x-2"
                      >
                        <div className="flex justify-between items-center space-x-2">
                          <div
                            className="h-4 w-4 rounded-full"
                            style={{
                              background:
                                payload.color || brandColor.lighten(index / 10),
                            }}
                          />
                          <p>{payload.name}</p>
                        </div>
                        <p>{formatAsCHF(payload.value)}</p>
                      </div>
                    ))}
                </div>
              );
            }}
          />
          <Legend />
          {areas
            .filter((area) => areasWithValues.includes(area.dataKey))
            .map((area, index) => {
              const shade = brandColor.lighten(index / 7.5);
              const color = area.color || shade;
              return (
                <Fragment key={area.name}>
                  <defs>
                    <linearGradient
                      id={`color${index}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor={color} stopOpacity={1} />
                      <stop offset="95%" stopColor={color} stopOpacity={0.8} />
                    </linearGradient>
                  </defs>
                  <Area
                    key={area.dataKey}
                    type={"stepBefore"}
                    name={area.name}
                    dataKey={area.dataKey}
                    fill={`url(#color${index})`}
                    fillOpacity="0.9"
                    stroke={color}
                    strokeWidth="0"
                    connectNulls={true}
                    stackId={type === "age" ? "year" : "month"}
                    legendType="square"
                    radius="20"
                  />
                </Fragment>
              );
            })}
          {lines.map((line) => (
            <Line
              key={line.dataKey}
              dot={false}
              animationDuration={1000}
              legendType="plainline"
              name={line.name}
              type="stepBefore"
              dataKey={line.dataKey}
              stroke={line.color}
              strokeWidth={2}
            />
          ))}
          {events.map((event, index) => (
            <ReferenceLine
              key={event.label + index}
              isFront
              x={event.month || event.year}
            >
              <Label
                value={event.label}
                position="top"
                angle={-45}
                className="text-sm"
              />
            </ReferenceLine>
          ))}
        </ComposedChart>
      </ResponsiveContainer>
      <h3 className="block md:hidden p-4">
        {t(
          "The graphic with all the details is available only on larger screens"
        )}
      </h3>
    </>
  );
}
