import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ConsultationContext,
  WhitelabelContext,
} from "../../../../../../contexts";
import Button from "../../../../../../components/Button";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

export default function Solutions({ customerID, type, cause }) {
  const { t } = useTranslation();
  const { consultationID } = useParams();

  const consultation = useContext(ConsultationContext);
  const whiteLabeler = useContext(WhitelabelContext);

  const [solutions, setSolutions] = useState([]);

  const { name } = whiteLabeler || {};
  const { scenarios = {} } = consultation;

  useEffect(() => {
    if (Object.keys(scenarios).includes(type)) {
      setSolutions(
        type === "age"
          ? scenarios[type].solutions
          : scenarios[type][customerID][cause].solutions,
      );
    }
  }, [cause, customerID, scenarios, type]);

  return solutions && solutions.length ? (
    <div
      className={classNames(
        whiteLabeler
          ? `border-${name} border-${name}-brand border-${name}-width`
          : "border",
        "bg-green-50 rounded-lg p-4 space-y-4",
      )}
    >
      <h2 className="text-2xl font-medium">{t("Your solutions:")}</h2>
      {solutions.map(solution => (
        <div
          key={solution.label}
          className="flex border shadow-lg bg-gray-50 text-black px-4 rounded-lg justify-between  items-center w-8/12"
        >
          <p className="font-medium pr-4">{solution.label}</p>
          <Button
            to={`/consultations/${consultationID}/${
              solution.type === "insuranceProvisionPlan"
                ? "insurances"
                : "assets"
            }`}
          >
            {t("Edit")}
          </Button>
        </div>
      ))}
    </div>
  ) : null;
}
