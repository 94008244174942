import React, { useContext } from "react";
import { ErrorMessage, Field } from "formik";
import StyledErrorMessage from "./StyledErrorMessage";
import classNames from "classnames";
import { WhitelabelContext } from "../../../contexts";

export default function CheckboxInputGroup(props) {
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};
  return (
    <>
      <label className="flex space-x-2 items-center">
        <Field
          type="checkbox"
          {...props}
          className={classNames(
            whiteLabeler
              ? `focus:ring-${name}-brand text-${name}-brand`
              : "focus:ring-brand text-brand",
            "rounded opacity-50 checked:opacity-100 focus:opacity-100",
          )}
        />
        <span>{props.label}</span>
      </label>
      <ErrorMessage name={props.name} render={StyledErrorMessage} />
    </>
  );
}
