import { useContext, useEffect, useMemo, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import {
  ConsultationContext,
  ConsultationsContext,
  CustomersContext,
} from "../contexts";
import AppLayout from "../layouts/AppLayout";
import WizardLayout from "../layouts/WizardLayout";
import MainPerson from "../pages/consultations/consultation/wizard/MainPerson";
import People from "../pages/consultations/consultation/people/People";
import Budget from "../pages/consultations/consultation/budget/Budget";
import Insurances from "../pages/consultations/consultation/insurances/Insurances";
import Assets from "../pages/consultations/consultation/assets/Assets";
import Risks from "../pages/consultations/consultation/risks/Risks";
import Partner from "../pages/consultations/consultation/wizard/Partner";
import Children from "../pages/consultations/consultation/wizard/Children";
import Income from "../pages/consultations/consultation/wizard/Income";
import Partnership from "../pages/consultations/consultation/wizard/Partnership";
import SplashScreen from "../components/SplashScreen";

export default function ConsultationRoutes() {
  const consultations = useContext(ConsultationsContext);

  const { consultationID } = useParams();
  const location = useLocation();

  const [customers, setCustomers] = useState({});

  const consultation = useMemo(
    () => consultations.find(c => c.id === consultationID),
    [consultationID, consultations],
  );

  useEffect(() => {
    if (consultation) {
      const { grownUps, children } = consultation?.customers || {};
      const customerIDs = [...(grownUps || []), ...(children || [])];
      const db = getFirestore();

      const unsubscribers = customerIDs.map(customerID => {
        const ref = doc(db, "customers", customerID);
        return onSnapshot(ref, snap =>
          setCustomers(v => ({ ...v, [customerID]: snap.data() })),
        );
      });
      return () => unsubscribers.forEach(fn => fn());
    }
  }, [consultation]);

  // Make sure that we've loaded the consultation – otherwise users might falsely be redirected to the wizard:
  if (!consultation) {
    return <SplashScreen />;
  }

  const wizardSteps = [
    {
      path: "person",
      element: <MainPerson />,
    },
    {
      path: "partnership",
      element: <Partnership />,
    },
    {
      path: "partner",
      element: <Partner />,
    },
    {
      path: "children",
      element: <Children />,
    },
    {
      path: "income",
      element: <Income />,
    },
  ];

  const hasAddedAGrownUp = consultation?.customers?.grownUps?.length > 0;
  const hasAddedABudget =
    consultation?.budgets && Object.keys(consultation.budgets).length > 0;
  const hasCompletedWizard = hasAddedAGrownUp && hasAddedABudget;

  return (
    <ConsultationContext.Provider value={consultation}>
      <CustomersContext.Provider value={customers}>
        <Routes location={location.state?.backgroundLocation || location}>
          <Route
            path="/"
            element={
              hasCompletedWizard ? (
                <AppLayout />
              ) : (
                <Navigate to="wizard" replace />
              )
            }
          >
            <Route
              index
              element={<Navigate to="risks" state={location.state} replace />}
            />
            <Route path="risks" element={<Risks />} />
            <Route path="assets" element={<Assets />} />
            <Route path="insurances" element={<Insurances />} />
            <Route path="budget" element={<Budget />} />
            <Route path="people" element={<People />} />
            <Route path="*" element={"Not found"} />
          </Route>
          <Route path="/wizard" element={<WizardLayout steps={wizardSteps} />}>
            <Route
              index
              element={<Navigate to="person" state={location.state} />}
            />
            {wizardSteps.map((step, index) => (
              <Route key={index} path={step.path} element={step.element} />
            ))}
          </Route>
        </Routes>
      </CustomersContext.Provider>
    </ConsultationContext.Provider>
  );
}
