import React, { useContext, useEffect, useState } from "react";
import ClosableDialogWithTitle from "./ClosableDialogWithTitle";
import { Form, Formik } from "formik";
import InputGroup from "../fields/InputGroup";
import Button from "../../Button";
import { getAuth, EmailAuthProvider, linkWithCredential } from "firebase/auth";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { doc, getFirestore, serverTimestamp, setDoc } from "firebase/firestore";
import CheckboxInputGroup from "../fields/CheckboxInputGroup";
import { UserContext, WhitelabelContext } from "../../../contexts";
import classNames from "classnames";

export default function RegisterDialog() {
  const user = useContext(UserContext);

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { backgroundLocation } = state;

  const whiteLabeler = useContext(WhitelabelContext);

  const [signedUp, setSignedUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { name } = whiteLabeler || {};
  const auth = getAuth();

  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const next = searchParams.get("next");

  const accountSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("Too Short!"))
      .max(50, t("Too Long!"))
      .required(t("Required")),
    lastName: Yup.string()
      .min(2, t("Too Short!"))
      .max(50, t("Too Long!"))
      .required(t("Required")),
    email: Yup.string().email().required(t("Required")),
    password: Yup.string()
      .required(t("No password provided."))
      .min(8, t("Password is too short - should be 8 chars minimum.")),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("password"), null],
      t("Passwords must match"),
    ),
    consent: Yup.bool().oneOf(
      [true],
      "You need to agree to terms & conditions",
    ),
  });
  useEffect(() => {
    const redirectToLink = async () => {
      if (signedUp && user) {
        const { firstName, lastName, email } = user;
        const userParams = `contact_forename=${firstName}&contact_surname=${lastName}&contact_email=${email}`;
        const payrexxUrl = `${next}&${userParams}`;
        window.location.href = payrexxUrl;
      }
    };
    redirectToLink();
  }, [auth.currentUser.uid, next, signedUp, user]);

  const onSubmit = async (values, form) => {
    setIsLoading(true);
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(
      values.email,
      values.password,
    );

    const userRef = doc(getFirestore(), "users", auth.currentUser.uid);
    try {
      await linkWithCredential(auth.currentUser, credential);
      await setDoc(userRef, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        created: serverTimestamp(),
        modified: serverTimestamp(),
      });
      if (next === "/validation-code") {
        navigate(next, {
          state: { backgroundLocation, page: "register" },
        });
      } else if (next === null) {
        navigate("/account");
      } else {
        setSignedUp(true);
      }
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        form.setFieldError(
          "email",
          t("There is already an account with this email address"),
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ClosableDialogWithTitle
      close={() => navigate(-1)}
      isOpen={true}
      title={t("Create your account")}
    >
      <Formik
        initialValues={{
          firstName,
          lastName,
          email: "",
          password: "",
          passwordConfirmation: "",
          consent: false,
        }}
        validationSchema={accountSchema}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className="space-y-4">
              <InputGroup
                type="text"
                name="firstName"
                label={t("First Name")}
              />
              <InputGroup type="text" name="lastName" label={t("Last Name")} />
              <InputGroup type="email" name="email" label={t("Email")} />
              <InputGroup
                type="password"
                name="password"
                label={t("Password")}
              />
              <InputGroup
                type="password"
                name="passwordConfirmation"
                label={t("Confirm Password")}
              />
              <div className="flex space-x-2">
                <CheckboxInputGroup
                  name="consent"
                  label={t("I agree to terms & conditions")}
                />
                <Link
                  to={"/terms"}
                  className={classNames(
                    whiteLabeler ? `text-${name}-brand` : "text-brand",
                    "font-semibold",
                  )}
                >
                  {t("View")}
                </Link>
              </div>
              <Button isLoading={isLoading} type="submit" className="my-3">
                {t("Create account")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ClosableDialogWithTitle>
  );
}
