import React, { useContext } from "react";
import { Form, Formik } from "formik";
import InputGroup from "./shared/fields/InputGroup";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Button from "./Button";
import AppstoreLogo from "../assets/appstore.svg";
import PlaystoreLogo from "../assets/playstore.svg";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { addDays, format } from "date-fns";
import { WhitelabelContext } from "../contexts";
import ClosableDialogWithTitle from "./shared/dialogs/ClosableDialogWithTitle";
import { useLocation, useNavigate } from "react-router-dom";

export default function PassengerForm({ close }) {
  const whiteLabeler = useContext(WhitelabelContext);

  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { backgroundLocation, page } = state;
  const { name } = whiteLabeler || {};

  const CodeSchema = Yup.object().shape({
    licenseCode: Yup.string().required().label(t("License Code")),
  });

  return (
    <ClosableDialogWithTitle
      close={() =>
        // If the page is from the register dialog, we navigate back two pages
        backgroundLocation.pathname === "/packages" && page === "register"
          ? navigate(-2)
          : navigate(-1)
      }
      isOpen={true}
      title={t("Validation Code")}
    >
      <Formik
        enableReinitialize
        initialValues={{ licenseCode: "" }}
        validationSchema={CodeSchema}
        onSubmit={async (values, form) => {
          if (values.licenseCode === "caveo2022") {
            await updateDoc(
              doc(getFirestore(), "users", getAuth().currentUser.uid),
              {
                subscription: {
                  name: "passenger",
                  validUntil: format(
                    new Date(addDays(new Date(), 90)),
                    "yyyy-MM-dd",
                  ),
                },
              },
            );
            close();
          } else {
            form.setFieldError("licenseCode", t("Invalid code"));
          }
        }}
      >
        {() => (
          <Form>
            <InputGroup
              type="text"
              name="licenseCode"
              label={t("Enter the validation code from your Caveo app below")}
            />
            <Button type={"submit"} className="mt-5">
              {t("Submit")}
            </Button>
          </Form>
        )}
      </Formik>
      <div className="mt-5">
        <p>
          {t("Don't have a code yet? Get it from")}{" "}
          <a
            href={"https://www.caveo.ch"}
            className={whiteLabeler ? `text-${name}-brand` : "text-brand"}
            rel="noreferrer"
            target="_blank"
          >
            caveo.ch
          </a>
        </p>

        <div className="flex space-x-4 mt-5">
          <p>{t("Or download the Caveo mobile app")}:</p>
          <a
            href={"https://apps.apple.com/app/caveo/id1548480677 "}
            rel="noreferrer"
            target="_blank"
          >
            <img src={AppstoreLogo} className="w-5 h-5" alt="AppStore" />
          </a>
          <a
            href={
              "https://play.google.com/store/apps/details?id=ch.caveo.android"
            }
            rel="noreferrer"
            target="_blank"
          >
            <img src={PlaystoreLogo} className="w-5 h-5" alt="PlayStore" />
          </a>
        </div>
      </div>
    </ClosableDialogWithTitle>
  );
}
