import {
  collection,
  deleteDoc,
  doc,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClosableDialogWithTitle from "../../../../components/shared/dialogs/ClosableDialogWithTitle";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import InsuranceForm from "./InsuranceForm";
import Card from "../../../../components/Card";
import { useParams } from "react-router-dom";
import NoGrownUps from "../../../../components/NoGrownUps";
import createAndSaveSolutions from "../../../../utils/createAndSaveSolutions";
import AddButton from "../../../../components/AddButton";

export default function Insurances() {
  const { t } = useTranslation();
  const { consultationID } = useParams();

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const [dialog, setDialog] = useState(null);
  const [insurances, setInsurances] = useState([]);

  useEffect(() => {
    if (consultation?.id) {
      const firestore = getFirestore();
      const insurancesCollection = collection(
        firestore,
        "consultations",
        consultation.id,
        "insurances",
      );
      return onSnapshot(insurancesCollection, snapshot =>
        setInsurances(
          snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
        ),
      );
    }
  }, [consultation?.id]);

  const onDelete = async insurance => {
    const ref = doc(
      getFirestore(),
      "consultations",
      consultation.id,
      "insurances",
      insurance.id,
    );
    // Delete the insurance:
    await deleteDoc(ref);
    // Recreate the solutions:
    await createAndSaveSolutions(consultationID);
    close();
  };

  if (!consultation) {
    return null;
  }

  const riskLabels = {
    survival: t("Survival"),
    invalidity: t("Invalidity"),
    death: t("Death"),
  };

  const grownUps = consultation.customers?.grownUps || [];
  const close = () => setDialog(null);
  return (
    <div className="flex flex-col space-y-4">
      <div>
        <div className="flex justify-between items-center">
          <h1>{t("Insurances")}</h1>
          <AddButton
            onClick={() =>
              setDialog({
                title: t("Add insurance"),
                content: <InsuranceForm close={close} />,
              })
            }
          />
        </div>
      </div>
      {grownUps?.length ? (
        grownUps.map(customerID => {
          const customer = customers[customerID];
          const customerInsurances = insurances.filter(
            i => i.customer === customerID,
          );
          return (
            <div key={customerID} className="flex flex-col space-y-4">
              <h2>
                {customer?.firstName} {customer?.lastName}
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {customerInsurances?.length ? (
                  customerInsurances.map((insurance, index) => (
                    <button
                      key={insurance.id}
                      onClick={() =>
                        setDialog({
                          title: t("Edit insurance"),
                          content: (
                            <InsuranceForm
                              insurance={insurance}
                              index={index}
                              close={close}
                              onDelete={() => onDelete(insurance)}
                            />
                          ),
                        })
                      }
                    >
                      <Card className="shadow bg-white p-6 rounded text-left flex flex-col space-y-4">
                        <h3 className="font-medium">{insurance.name}</h3>
                        <p>
                          CHF {insurance.premium.value}/
                          {insurance.premium.interval}
                        </p>
                        <div className="flex space-x-2">
                          {insurance.coveredRisks.map(risk => (
                            <div
                              key={risk}
                              className="border p-2 rounded bg-gray-50 leading-none"
                            >
                              {riskLabels[risk]}
                            </div>
                          ))}
                        </div>
                      </Card>
                    </button>
                  ))
                ) : (
                  <p className="opacity-50">
                    {t("You don't have any insurances yet.")}
                  </p>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <NoGrownUps />
      )}
      <ClosableDialogWithTitle
        close={close}
        isOpen={!!dialog}
        title={dialog?.title}
      >
        {dialog?.content}
      </ClosableDialogWithTitle>
    </div>
  );
}
