import React, { useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { XIcon } from '@heroicons/react/outline';
import { doc, deleteDoc, getFirestore, getDoc } from 'firebase/firestore';
import { getAuth, deleteUser } from 'firebase/auth';
import Logo from '../components/Logo';
import ClosableDialogWithTitle from '../components/shared/dialogs/ClosableDialogWithTitle';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';

export default function WizardLayout() {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { consultationID } = useParams();

  const [visibleDialog, setDialog] = useState(null);

  const closeDialog = () => setDialog(null);

  const handleModalClose = async () => {
    const firestore = getFirestore();
    // Get consultation:
    const consultationRef = doc(firestore, 'consultations', consultationID);
    const consultation = await getDoc(consultationRef).then(snapshot =>
      snapshot.data(),
    );
    const wizardStepPath = /[^/]*$/.exec(window.location.pathname)[0];
    const currentUser = getAuth().currentUser;
    if (currentUser?.isAnonymous && !consultation.budgets) {
      // Delete customers:
      const { customers } = consultation;
      const { grownUps, children } = customers || {};
      const customerIDs = [...(grownUps || []), ...(children || [])];
      await Promise.all([
        ...customerIDs.map(customerID =>
          deleteDoc(doc(firestore, 'customers', customerID)),
        ),
        deleteDoc(consultationRef),
        deleteUser(currentUser),
      ]);
      navigate('/');
    } else {
      if (wizardStepPath === 'income') {
        setDialog({
          title: t('Missing info'),
          content: (
            <div>
              <p>{t('You need to fill your income first')}</p>
              <Button className="float-right" onClick={closeDialog}>
                {t('Ok')}
              </Button>
            </div>
          ),
        });
      }
    }
  };

  return (
    <div className="h-screen flex flex-col space-y-4 bg-gray-50">
      <div className="flex p-4 justify-between items-center">
        <Link to={'/'}>
          <Logo />
        </Link>
        <div className="close text-right">
          <button onClick={handleModalClose}>
            <XIcon className="h-7 w-7" />
          </button>
        </div>
      </div>
      <div className="overflow-y-auto flex-1 pb-8">
        <Outlet />
      </div>
      <ClosableDialogWithTitle
        isOpen={!!visibleDialog}
        close={closeDialog}
        title={visibleDialog?.title}
      >
        {visibleDialog?.content}
      </ClosableDialogWithTitle>
    </div>
  );
}
