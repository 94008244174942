import { format } from "date-fns";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CreateConsultationButton from "../../components/CreateConsultationButton";
import Header from "../../components/landing/Header";
import { ConsultationsContext } from "../../contexts";

export default function Consultations() {
  const consultations = useContext(ConsultationsContext);
  const { t } = useTranslation();

  return (
    <div className="flex flex-col p-4 space-y-4 bg-gray-50 min-h-screen">
      <Header />
      <div className="flex flex-col p-4 space-y-4 bg-gray-50 min-h-screen">
        <h1>{t("Consultations")}</h1>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {consultations.map((c, index) => (
            <Link
              key={c.id}
              to={c.id}
              className="bg-white rounded shadow p-4 text-xl"
            >
              <p className="text-xl">
                {t("Consultation")} {index + 1}
              </p>
              {c.created && (
                <p className="opacity-50 text-sm">
                  {t("Created on")} {format(c.created?.toDate(), "dd.MM.yyyy")}
                </p>
              )}
            </Link>
          ))}
          <CreateConsultationButton label={t("Create new consultation")} />
        </div>
      </div>
    </div>
  );
}
