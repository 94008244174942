import { InformationCircleIcon } from "@heroicons/react/solid";
import { Trans, useTranslation } from "react-i18next";
import { formatAsCHF } from "../../../../../../utils/helpers";
import { useContext } from "react";
import { UserContext, WhitelabelContext } from "../../../../../../contexts";
import classNames from "classnames";

export default function Overrun({ totals }) {
  const { t } = useTranslation();

  const { firstName } = useContext(UserContext);
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};
  // How much would the customer be able to save?
  const { overrun, riskAfter } = totals.cumulative;

  if (!overrun || !riskAfter) {
    return null;
  }

  return (
    <div
      className={classNames(
        whiteLabeler
          ? `border-${name}-width border-${name}-dark/40 bg-${name}-dark/5 text-${name}-dark/40`
          : "border bg-gray-50",
        " rounded-lg p-4 space-y-2",
      )}
    >
      <p className="flex items-center space-x-1 opacity-50">
        <InformationCircleIcon className="h-6 w-6 inline" />
        <span className="font-medium uppercase">{t("Note")}</span>
      </p>
      <p className="text-2xl">
        <Trans i18nKey={"OverrunMessage"}>{{ firstName }}</Trans>,
      </p>
      <p className="text-2xl">
        {t("You have an overrun of")} <strong>{formatAsCHF(overrun)}</strong>.{" "}
        {t("This might reduce your risk.")}{" "}
        {t(
          "You can use these overruns for a potential solution (bank or insurance). We are happy to help you, just contact us via the chat button.",
        )}
      </p>
    </div>
  );
}
