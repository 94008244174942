import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useScenarios() {
  const { t } = useTranslation();

  const scenarios = useMemo(() => {
    return [
      {
        label: t("Age"),
        type: "age",
        cause: "",
      },
      {
        label: t("Invalidity by accident"),
        type: "invalidity",
        cause: "accident",
      },
      {
        label: t("Invalidity by sickness"),
        type: "invalidity",
        cause: "sickness",
      },
      {
        label: t("Death by accident"),
        type: "death",
        cause: "accident",
      },
      {
        label: t("Death by sickness"),
        type: "death",
        cause: "sickness",
      },
    ];
  }, [t]);

  return scenarios;
}
