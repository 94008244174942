import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import usePrecision from "../hooks/usePrecision";
import { useTranslation } from "react-i18next";
import {
  ConsultationContext,
  CustomersContext,
  WhitelabelContext,
} from "../contexts";
import classNames from "classnames";

export default function MissingInformation() {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const { precisionObjects } = usePrecision();

  const customers = useContext(CustomersContext);
  const consultation = useContext(ConsultationContext);
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};
  const grownUps = consultation?.customers?.grownUps || [];
  const remainingTests = precisionObjects?.map(item => {
    return Object.entries(item).filter(value => !value[1]);
  });

  return remainingTests.map(
    (test, index) =>
      Boolean(test.length) && (
        <div key={index} className="divide-y flex flex-col">
          <p className="p-3 font-semibold bg-gray-50">
            {t("Add these entries for")}{" "}
            {index === 0
              ? customers?.[grownUps[0]]?.firstName
              : customers?.[grownUps[1]]?.firstName}
          </p>
          {remainingTests[index].map((test, i) => (
            <Link
              key={i}
              to={`/consultations/${consultationID}/${
                test[0] === t("Spendings") ? "budget" : "assets"
              }`}
              className={classNames(
                whiteLabeler ? `text-${name}-brand` : "text-brand",
                "p-3 hover:bg-gray-50",
              )}
            >
              <p>{test[0]}</p>
            </Link>
          ))}
        </div>
      ),
  );
}
