import { addYears, format } from "date-fns";
import CryptoJS from "crypto-js";
import { updateDoc } from "firebase/firestore";

/**
 * Update the user's subscription if the link is from a caveo app
 **/
export function updateUserSubscription(userRef) {
  // The subscription is valid one year from today's date
  const today = new Date();
  const oneYearFromToday = addYears(today, 1);
  const oneYearFromTodayDate = format(oneYearFromToday, "yyyy-MM-dd");
  const passengerSubscription = {
    name: "passenger",
    validUntil: oneYearFromTodayDate,
  };
  return updateDoc(userRef, { subscription: passengerSubscription });
}

export function isVoucherValid(voucher) {
  // Decrypt the voucher to check if it matches the secret passphrase
  const decrypted = CryptoJS.AES.decrypt(voucher, "caveo-riskcockpit");
  const unlockCode = decrypted.toString(CryptoJS.enc.Utf8);
  return unlockCode && unlockCode === "caveo2022";
}
