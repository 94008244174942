import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as en from "./locales/en/en.json";
import * as de from "./locales/de/de.json";

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

const browserLanguage = navigator.language || navigator.userLanguage;

const language = browserLanguage.slice(0, 2);

const availableLanguages = Object.keys(resources);

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: availableLanguages.includes(language) ? language : "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    nsSeparator: false,
    keySeparator: false,

    // do not load a fallback
    fallbackLng: false,

    // allow an empty value to count as invalid (by default is true)
    returnEmptyString: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
