import React, { useEffect, useState, useContext } from "react";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import { getAuth, sendPasswordResetEmail, signOut } from "firebase/auth";
import { PencilIcon } from "@heroicons/react/solid";
import { Form, Formik } from "formik";
import InputGroup from "../components/shared/fields/InputGroup";
import ClosableDialogWithTitle from "../components/shared/dialogs/ClosableDialogWithTitle";
import * as Yup from "yup";
import {
  doc,
  getFirestore,
  getDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/Logo";
import { WhitelabelContext } from "../contexts";
import classNames from "classnames";

export default function Account() {
  const { t } = useTranslation();
  const auth = getAuth();
  const db = getFirestore();
  let navigate = useNavigate();
  //let location = useLocation();
  const [userData, setUserData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};

  const user = auth?.currentUser || {};
  const userID = user?.uid;

  useEffect(() => {
    if (userID) {
      const userRef = doc(db, "users", userID);
      getDoc(userRef).then(res => setUserData(res.data()));
    }
  }, [db, userID]);

  const firstName = userData.firstName;
  const lastName = userData.lastName;

  const resetPassword = () => {
    sendPasswordResetEmail(auth, user?.email).then(() => {
      alert(t("Password reset email sent!"));
    });
  };

  const accountSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t("Too Short!"))
      .max(50, t("Too Long!"))
      .required(t("Required")),
    lastName: Yup.string()
      .min(2, t("Too Short!"))
      .max(50, t("Too Long!"))
      .required(t("Required")),
    email: Yup.string().email().required(t("Required")),
  });

  const logOut = () => {
    signOut(auth).then(() => {
      navigate("/");
    });
  };

  const onSubmit = async values => {
    const userInfo = {
      firstName: userData.firstName,
      lastName: userData.lastName,
    };
    const formInfo = {
      firstName: values.firstName,
      lastName: values.lastName,
    };

    //TODO: Make email updating functional
    /*if (values.email !== userData.email) {
      try {
        await updateEmail(user, values.email);
      } catch (error) {
        navigate("/login", { state: { backgroundState: location } });
      }
    }*/

    if (JSON.stringify(userInfo) !== JSON.stringify(formInfo)) {
      const customerRef = doc(db, "users", userID);
      return updateDoc(customerRef, {
        ...formInfo,
        modified: serverTimestamp(),
      });
    }

    setIsOpen(false);
  };

  return (
    <div>
      <div className="flex justify-between p-4 border-b">
        <Link className="flex items-center space-x-2 min-h-[40px]" to={"/"}>
          {whiteLabeler ? (
            <>
              {whiteLabeler.logo?.src ? (
                <img
                  src={whiteLabeler.logo?.src}
                  alt={whiteLabeler.logo?.name}
                  className="w-36"
                />
              ) : (
                <span className="text-xl font-semibold opacity-70 ml-3">
                  {whiteLabeler.logo?.name}
                </span>
              )}
              <span className="text-xl opacity-70 ml-3">
                {t("RiskCockpit")}
              </span>
            </>
          ) : (
            <Logo />
          )}
        </Link>
        <div className="flex items-center">
          <p
            className={classNames(
              whiteLabeler ? `text-${name}-brand` : "text-brand",
              "cursor-pointer text-xl",
            )}
            onClick={logOut}
          >
            {t("Log out")}
          </p>
        </div>
      </div>
      <div className="md:mx-24 mx-5 mt-5">
        <h1 className="mb-5">{t("Account")}</h1>
        <h3>{t("Profile")}</h3>
        <div className="space-y-5 my-5 relative w-1/3">
          <div>
            <label className="opacity-50">{t("First Name")}</label>
            <p>{firstName}</p>
          </div>
          <div>
            <label className="opacity-50">{t("Last Name")}</label>
            <p>{lastName}</p>
          </div>
          <div>
            <label className="opacity-50">{t("Email")}</label>
            <p>{user.email}</p>
          </div>
          <button
            onClick={resetPassword}
            className={whiteLabeler ? `text-${name}-brand` : "text-brand"}
          >
            {t("Reset Password")}
          </button>
        </div>

        <Button className="mr-3" onClick={() => setIsOpen(true)}>
          <PencilIcon className="h-5 w-5 inline-block" />{" "}
          <span className="inline-block">Edit details</span>
        </Button>
      </div>
      <ClosableDialogWithTitle
        close={() => setIsOpen(false)}
        isOpen={isOpen}
        title={t("Your personal details")}
      >
        <Formik
          initialValues={{
            firstName: firstName,
            lastName: lastName,
            email: user.email,
          }}
          validationSchema={accountSchema}
          onSubmit={onSubmit}
        >
          {({ dirty }) => (
            <Form>
              <div className="space-y-4">
                <InputGroup type="text" name="firstName" label={"First Name"} />
                <InputGroup type="text" name="lastName" label={"Last Name"} />
                {/* <InputGroup type="email" name="email" label={"Email"} />*/}
                {dirty && (
                  <Button type="submit" className="my-3">
                    {t("Save changes")}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </ClosableDialogWithTitle>
    </div>
  );
}
