import dataTypes from "../../dataTypes";

const ageComponents = {
  lines: [dataTypes.spendings],
  areas: [
    dataTypes.occupation.salary,
    dataTypes.firstPillar.pension,
    dataTypes.secondPillar.pension,
    dataTypes.thirdPillar,
    dataTypes.solutions,
    dataTypes.situation.uncovered,
  ],
};

export default ageComponents;
