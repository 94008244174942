import { XIcon } from "@heroicons/react/solid";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationContext } from "../../../../../contexts";

export default function ScenarioHeader({ close, type, cause, customer }) {
  const { t } = useTranslation();
  const consultation = useContext(ConsultationContext);

  const title = useMemo(() => {
    if (type === "age") {
      return t("Ihre Situation im Alter");
    }
    const isSingle = consultation.customers?.grownUps?.length === 1;
    return `Falls ${isSingle ? "Sie" : customer.firstName} wegen ${
      cause === "sickness" ? "einer Krankheit" : "eines Unfalls"
    } ${
      type === "death"
        ? `${isSingle ? "sterben" : "stirbt"}`
        : `nicht mehr arbeiten ${isSingle ? "können" : "kann"}`
    }.`;
  }, [
    cause,
    consultation.customers?.grownUps?.length,
    customer.firstName,
    t,
    type,
  ]);

  return (
    <div className="border-b flex justify-between p-5 flex-none">
      <div className="flex items-center space-x-4">
        <h3>{title}</h3>
      </div>
      <button type="button" onClick={close}>
        <XIcon className="h-8 w-8" />
      </button>
    </div>
  );
}
