import React, { useEffect } from "react";
import Loader from "../components/Loader";
import { getAuth, signInAnonymously } from "firebase/auth";
import { createConsultation } from "../database/consultations";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Wizard() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const goToWizard = async () => {
      try {
        // Create anonymous user:
        const userCredential = await signInAnonymously(getAuth());
        // Create a new consultation:
        const consultationRef = await createConsultation(
          userCredential.user.uid,
        );
        // Navigate to the wizard:
        navigate(`/consultations/${consultationRef.id}/wizard`);
      } catch (error) {
        console.error(error);
      }
    };
    goToWizard();
  }, [navigate]);

  return (
    <div className="text-center mt-20">
      <p className="mb-5">{t("Preparing your cockpit...")}</p>
      <Loader />
    </div>
  );
}
