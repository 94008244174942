import { addYears, differenceInMonths } from "date-fns";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
} from "firebase/firestore";

/**
 * Re-creates solutions from all assets and insurances and saves them in the consultation doc.
 */
export default async function createAndSaveSolutions(consultationID) {
  const firestore = getFirestore();

  const consultationRef = doc(firestore, "consultations", consultationID);
  const consultation = await getDoc(consultationRef).then((snapshot) =>
    snapshot.data()
  );
  // Get grown-ups:
  const grownUps = await Promise.all(
    consultation.customers.grownUps.map((customerID) =>
      getDoc(doc(firestore, "customers", customerID)).then((snapshot) => ({
        id: snapshot.id,
        ...snapshot.data(),
      }))
    )
  );
  // Get all insurances of this consultation.
  const insurancesCollection = collection(consultationRef, "insurances");
  const insurances = await getDocs(insurancesCollection).then((snapshot) =>
    snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  );
  // Create the update data:
  let data = {
    "scenarios.age.solutions": createAgeSolutions(
      consultation,
      insurances,
      grownUps
    ),
    "scenarios.invalidity": createScenarioWithSolutions(
      consultation,
      insurances,
      "invalidity"
    ),
    "scenarios.death": createScenarioWithSolutions(
      consultation,
      insurances,
      "death"
    ),
  };

  // Save these solutions in the consultation.
  return updateDoc(consultationRef, data);
}

const baseSolution = {
  isNewSolution: false,
};

const insuranceSolution = {
  ...baseSolution,
  type: "insuranceProvisionPlan",
};

const bankSolution = {
  ...baseSolution,
  type: "bankProvisionPlan",
};

/**
 * Create solutions for the age scenario from bonded third pillar assets and free assets.
 */
function createAgeSolutions(consultation, insurances, grownUps) {
  const assetSolutions = createSolutionsFromAssets(consultation);
  const insuranceSolutions = createSolutionsFromAgeInsurances(
    insurances,
    grownUps
  );
  return [...assetSolutions, ...insuranceSolutions];
}

/**
 * Creates a solution for every free asset.
 */
function createSolutionsFromAssets(consultation) {
  const { provisions } = consultation;
  return Object.keys(provisions).reduce((array, customerID) => {
    const customerProvision = provisions[customerID];
    const { freeAssets = [] } = customerProvision;
    freeAssets.forEach((asset) =>
      array.push({
        ...bankSolution,
        value: asset.value,
        label: asset.description,
      })
    );
    return array;
  }, []);
}

/**
 * Creates a solution for every age insurance.
 */
function createSolutionsFromAgeInsurances(insurances, grownUps) {
  const insurancesWithAgeBenefits = insurances.filter((i) =>
    i.coveredRisks.includes("survival")
  );
  const monthsToRetirementByCustomer = getMonthsToRetirement(grownUps);
  return insurancesWithAgeBenefits.map((insurance) => {
    const { premium, benefits, customer } = insurance;
    const { guaranteedCapital, assumedReturn } = benefits.survival;
    const solution = { ...insuranceSolution, label: insurance.name };
    if (guaranteedCapital) {
      solution.value = guaranteedCapital;
    } else {
      const { interval, value } = premium;
      const premiumValue = interval === "month" ? value : value / 12;
      solution.value = calculateFutureValue({
        monthlyPayment: premiumValue * 0.9,
        monthsToRetirement: monthsToRetirementByCustomer[customer],
        assumedReturn: parseFloat(assumedReturn) || 0.003,
      });
    }
    return solution;
  });
}

/**
 * How many months until the persons will retire?
 */
function getMonthsToRetirement(grownUps) {
  return grownUps.reduce((object, grownUp) => {
    const { gender, birthDate } = grownUp;
    const birthday = new Date(birthDate);
    const retirementDate = addYears(birthday, gender === "m" ? 65 : 64);
    const numMonthsToRetirement = differenceInMonths(
      retirementDate,
      new Date()
    );
    object[grownUp.id] = numMonthsToRetirement;
    return object;
  }, {});
}

/**
 * Calculate the future value of an asset.
 */
function calculateFutureValue({
  currentValue = 0,
  monthlyPayment,
  monthsToRetirement,
  assumedReturn,
}) {
  const periods = monthsToRetirement - 1;
  const divisor = 12;
  let count = 0;
  let futureValue = currentValue;
  while (count < periods) {
    futureValue =
      (futureValue + parseInt(monthlyPayment)) * (1 + assumedReturn / divisor);
    count += 1;
  }
  return futureValue;
}

/**
 * Creates a new scenario object with update solutions.
 */
function createScenarioWithSolutions(consultation, insurances, scenarioType) {
  // Get the current scenario data which includes the key "risks":
  let scenarioData = { ...consultation.scenarios[scenarioType] };
  // Filter the insurances by what they cover:
  const insurancesByType = insurances.filter((i) =>
    i.coveredRisks.includes(scenarioType)
  );
  // These scenarios are saved by customer. To create solutions, we loop through them:
  const { grownUps } = consultation.customers || [];
  for (const customerID of grownUps) {
    // Get the customer's insurances:
    const customerInsurances = insurancesByType.filter(
      (i) => i.customer === customerID
    );
    // Create a solutions object to simplify the re-creation from scratch:
    const solutionsByCause = { sickness: [], accident: [] };
    // Add the solutions for every insurance to an object:
    for (const insurance of customerInsurances) {
      scenarioType === "invalidity"
        ? addSolutionsForInvalidityInsurance(
            solutionsByCause,
            insurance,
            customerID
          )
        : addSolutionsForDeathInsurance(
            solutionsByCause,
            insurance,
            customerID
          );
    }
    // Add the solutions to the scenario:
    Object.keys(solutionsByCause).forEach(
      (cause) =>
        (scenarioData[customerID][cause].solutions = solutionsByCause[cause])
    );
  }
  return scenarioData;
}

/**
 * Create solutions from an invalidity insurance.
 */
function addSolutionsForInvalidityInsurance(solutionsByCause, insurance) {
  const { sicknessPension, accidentPension, waitingPeriod } =
    insurance.benefits.invalidity;
  const baseSolution = {
    ...insuranceSolution,
    label: insurance.name,
    waitingPeriod,
  };

  if (sicknessPension && typeof sicknessPension.value === "number") {
    addSolutionForCause(
      "sickness",
      getPensionSolution(baseSolution, sicknessPension),
      solutionsByCause
    );
  }

  if (accidentPension && typeof accidentPension.value === "number") {
    addSolutionForCause(
      "accident",
      getPensionSolution(baseSolution, accidentPension),
      solutionsByCause
    );
  }
}

/**
 * Add death solutions to a passed object.
 */
function addSolutionsForDeathInsurance(solutionsByCause, insurance) {
  const baseSolution = {
    ...insuranceSolution,
    label: insurance.name,
  };
  const { guaranteedCapital, orphanPension } = insurance.benefits.death;
  // If orphan pension is set up
  if (orphanPension.value > 0) {
    // The orphan pension applies to both causes:
    ["sickness", "accident"].forEach((cause) => {
      addSolutionForCause(
        cause,
        getPensionSolution(baseSolution, orphanPension),
        solutionsByCause
      );
    });
  } else {
    // If no orphan pension but has a Guaranteed capital
    if (guaranteedCapital) {
      const solution = {
        ...baseSolution,
        value: guaranteedCapital,
        category: "lumpsum",
      };
      // The guaranteed capital applies to both causes:
      ["sickness", "accident"].forEach((cause) => {
        addSolutionForCause(cause, solution, solutionsByCause);
      });
    }
  }
}

/**
 * Create a solution for a pension.
 */
function getPensionSolution(baseSolution, pension) {
  return {
    ...baseSolution,
    pension,
  };
}

/**
 * Adds a solution for a cause to an object.
 */
function addSolutionForCause(cause, solution, solutionsByCause) {
  const currentSolutions = solutionsByCause[cause];
  solutionsByCause[cause] = currentSolutions
    ? [...currentSolutions, solution]
    : [solution];
  return solutionsByCause;
}
