import React from "react";
import { Form, Formik } from "formik";
import InputGroup from "./shared/fields/InputGroup";
import Button from "./Button";
import ClosableDialogWithTitle from "./shared/dialogs/ClosableDialogWithTitle";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default function LoginDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next");

  const onSubmit = async (values, form) => {
    try {
      await signInWithEmailAndPassword(
        getAuth(),
        values.email,
        values.password,
      );
    } catch (error) {
      const errorCode = error.code;
      if (errorCode === "auth/wrong-password") {
        form.setFieldError("password", t("Wrong password"));
      } else if (errorCode === "auth/user-not-found") {
        form.setFieldError(
          "email",
          t("There isn't an account registered to that email"),
        );
      } else if (errorCode === "auth/too-many-requests") {
        form.setFieldError(
          "password",
          t("Too many requests. Please try again later"),
        );
      }
    }
    // Close dialog and remain on the same page if we are logging in on the packages page
    if (next) {
      navigate(next, { replace: true });
    }
  };

  return (
    <ClosableDialogWithTitle
      close={() => navigate(-1)}
      isOpen={true}
      title={t("Log in")}
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={onSubmit}
      >
        {() => (
          <Form>
            <div className="space-y-4">
              <InputGroup type="email" name="email" label={"Email"} />
              <InputGroup type="password" name="password" label={"Password"} />
              <Button type="submit" className="my-3">
                {t("Login")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ClosableDialogWithTitle>
  );
}
