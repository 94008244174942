import { useTranslation } from "react-i18next";
import Button from "./Button";
import DeleteButton from "./DeleteButton";

export default function FormActions({ onDelete, dirty }) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between">
      {dirty && <Button type="submit">{t("Save")}</Button>}
      {onDelete && <DeleteButton onClick={onDelete} />}
    </div>
  );
}
