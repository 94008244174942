import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationContext } from "../../../../contexts";
import PersonBudget from "./PersonBudget";
import NoGrownUps from "../../../../components/NoGrownUps";

export default function Budget() {
  const { t } = useTranslation();
  const consultation = useContext(ConsultationContext);

  if (!consultation) {
    return null;
  }

  const grownUps = consultation.customers?.grownUps || [];
  return (
    <div className="flex flex-col space-y-4">
      <h1>{t("Budget")}</h1>
      {grownUps?.length ? (
        grownUps.map((customerID, index) => {
          return <PersonBudget key={index} customerID={customerID} />;
        })
      ) : (
        <NoGrownUps />
      )}
    </div>
  );
}
