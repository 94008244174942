const red = "#ff0050";
const green = "#34D399";
const brand = "#6647FF";
const brandLight1 = "#846bff";
const brandLight2 = "#937eff";
const brandLight3 = "#b2a3ff";
const yellow = "#fbbf24";
const yellowLight = "#fcd265";
const blue = "#1c4966";
const blueLight1 = "#296d98";
const blueLight2 = "#3792cb";
const blueLight3 = "#45b6fe";
const cyan = "#0891b2";

const dataTypes = {
  occupation: {
    total: {
      name: "Beruf",
      dataKey: "occupationTotal",
      color: brand,
    },
    previousNetIncome: {
      name: "Einkommen bisher (netto)",
      dataKey: "previousNetIncome",
      color: green,
    },
    minRequirement: {
      name: "Ausgaben",
      dataKey: "minRequirement",
      color: red,
    },
    salary: {
      dataKey: "salary",
      name: "Einkommen",
      color: brand,
    },
    KTG: {
      dataKey: "KTG",
      name: "Krankentaggeld",
      color: brandLight1,
    },
    LFZMIN: {
      dataKey: "LFZMIN",
      name: "Lohnfortzahlung (Minimum)",
      color: brandLight2,
    },
    LFZMAX: {
      dataKey: "LFZMAX",
      name: "Lohnfortzahlung (Maximum)",
      color: brandLight3,
    },
  },
  firstPillar: {
    total: {
      name: "Erste Säule",
      dataKey: "firstPillarTotal",
      color: yellow,
    },
    pension: {
      // Maybe firstPillarStatePension
      dataKey: "firstPillar",
      name: "AHV",
      color: yellow,
    },
    death: {
      widow: {
        dataKey: "firstPillarDeathWidow",
        name: "AHV-Hinterbliebenenrente",
        description: "Hinterbliebenenrente aus der ersten Säule",
        color: yellow,
      },
      orphan: {
        dataKey: "firstPillarDeathOrphan",
        name: "AHV-Waisenrente",
        description: "Waisenrente aus der ersten Säule",
        color: yellowLight,
      },
    },
    invalidity: {
      main: {
        dataKey: "firstPillarInvalidity",
        name: "IV-Rente aus 1. Säule",
        description: "Rente aus der Invaliditätsversicherung",
        color: yellow,
      },
      children: {
        dataKey: "firstPillarInvalidityKids",
        name: "IV-Kinderrente aus 1. Säule",
        description: "Kinderrente der Invaliditätsversicherung",
        color: yellowLight,
      },
    },
  },
  secondPillar: {
    total: {
      name: "Zweite Säule",
      dataKey: "secondPillarTotal",
      color: blue,
    },
    pension: {
      dataKey: "secondPillar",
      name: "Pensionskasse",
      color: blue,
    },
    uvg: {
      UVG: {
        dataKey: "UVG",
        name: "Unfallversicherung",
        color: blue,
      },
      daily: {
        dataKey: "UVGTaggeld",
        name: "Taggeld der Unfallversicherung",
        color: blueLight1,
      },
      orphan: {
        dataKey: "UVGOrphan",
        name: "Waisenrente aus Unfallversicherung",
        color: blueLight2,
      },
      widow: {
        dataKey: "UVGPensionWidow",
        name: "UVG-Hinterbliebenenrente",
        color: blueLight3,
      },
    },
    bvg: {
      supplemental: {
        main: {
          dataKey: "BVGsupplementalPension",
          name: "BVG-Subsidiärrente",
          color: blue,
        },
        orphan: {
          dataKey: "BVGsupplementalOrphanPension",
          name: "Waisenzusatzrente aus 2. Säule",
          color: blueLight1,
        },
        children: {
          dataKey: "BVGsupplementalKidsPension",
          name: "BVG-Subsidiärrente Kinder",
          color: blueLight2,
        },
      },
      invalidity: {
        main: {
          dataKey: "BVGInvalidityPension",
          name: "IV-Rente aus 2. Säule",
          color: blue,
        },
        children: {
          dataKey: "BVGInvalidityKidsPension",
          name: "IV-Kinderrente aus 2. Säule",
          color: blueLight1,
        },
      },
      death: {
        widow: {
          dataKey: "BVGWidowPension",
          name: "BVG-Hinterbliebenenrente",
          color: blue,
        },
        orphan: {
          dataKey: "BVGOrphanPension",
          name: "BVG-Waisenrente",
          color: blueLight1,
        },
      },
    },
  },
  thirdPillar: {
    dataKey: "thirdPillar",
    name: "Säule 3a",
    color: cyan,
  },
  solutions: {
    dataKey: "insurances",
    name: "Lösungen",
    color: green,
  },
  situation: {
    total: {
      dataKey: "situationTotal",
      name: "Resultat",
    },
    uncovered: {
      dataKey: "uncovered",
      name: "Ungedeckt",
      color: red + "75",
    },
    overrunAge: {
      dataKey: "remainingOverrun",
      name: "Nicht berücksichtigte Überschüsse",
    },
    overrun: {
      dataKey: "overrun",
      name: "Nicht berücksichtigte Überschüsse",
    },
  },
  spendings: {
    dataKey: "spendings",
    name: "Ausgaben",
    color: red,
  },
  incomeTotal: {
    name: "Einnahmen",
    dataKey: "incomeTotal",
  },
  beforeRetirement: {
    income: {
      dataKey: "incomeBeforeRetirement",
      name: "Einnahmen",
    },
    costs: {
      dataKey: "costsBeforeRetirement",
      name: "Ausgaben",
    },
  },
  afterRetirement: {
    costs: {
      name: "Ausgaben",
      dataKey: "costsAfterRetirement",
    },
    income: {
      name: "Einnahmen",
      dataKey: "incomeAfterRetirement",
    },
    balance: {
      name: "Saldo",
      dataKey: "balanceAfterRetirement",
    },
  },
};

export default dataTypes;
