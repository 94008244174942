import React, { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import RiskAssessmentCard from "./RiskAssessmentCard";
import Scenario from "./scenario/Scenario";
import useScenarios from "../../../../hooks/useScenarios";
import PrecisionIndicator from "../../../../components/PrecisionIndicator";
import NoGrownUps from "../../../../components/NoGrownUps";
import { createPortal } from "react-dom";

export default function Risks() {
  const { t } = useTranslation();
  const scenarios = useScenarios();

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const [visibleScenario, setVisibleScenario] = useState();
  const [scenariosArray, setScenariosArray] = useState([]);

  if (!consultation) {
    return null;
  }

  const grownUps = consultation.customers?.grownUps || [];

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex flex-wrap justify-between gap-2 items-center">
          <h1>{t("Risks")}</h1>
          <PrecisionIndicator />
        </div>
        {grownUps?.length ? (
          grownUps.map(customerID => {
            const customer = customers[customerID];
            return (
              <Fragment key={customerID}>
                <h2>
                  {customer?.firstName} {customer?.lastName}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                  {scenarios.map(({ label, type, cause }) => (
                    <RiskAssessmentCard
                      setScenariosArray={setScenariosArray}
                      key={label}
                      customerID={customerID}
                      label={label}
                      type={type}
                      cause={cause}
                      onClick={data => setVisibleScenario(data)}
                    />
                  ))}
                </div>
              </Fragment>
            );
          })
        ) : (
          <NoGrownUps />
        )}
      </div>
      {visibleScenario &&
        // needed to be fully visible on Safari
        createPortal(
          <Scenario
            {...visibleScenario}
            scenarios={scenariosArray}
            close={() => setVisibleScenario(null)}
          />,
          document.body,
        )}
    </>
  );
}
