import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationContext } from "../contexts";

/**
 * Calculates percentage of data completion and returns remaining items that need providing
 *@returns {{precisionObjects: array, percentage: number}}
 */
export default function usePrecision() {
  const { t } = useTranslation();
  const consultation = useContext(ConsultationContext);

  const { grownUps = [] } = consultation?.customers || {};

  return useMemo(() => {
    const precisionArray = grownUps?.map((id) => {
      const budget = consultation?.budgets?.[id] || {};
      const provision = consultation?.provisions?.[id] || {};
      // What data has the user provided?
      const precisionCheck = {
        [t("Income")]: Boolean(budget.income),
        [t("Spendings")]: Boolean(budget.spendings),
        [t("First Pillar")]: provision.firstPillar?.hasSummary,
        [t("Second Pillar")]: provision.secondPillar?.hasSummary,
        [t("Third Pillar")]: provision.thirdPillar !== 0,
      };
      const precisionValues = Object.values(precisionCheck);
      const validPrecisionValues = precisionValues.filter(Boolean);
      const percentage = validPrecisionValues.length / precisionValues.length;
      return { precisionCheck, percentage };
    });
    const percentageSum = precisionArray
      .map((p) => p.percentage) // Get just the values.
      .reduce((sum, value) => sum + value, 0); // Sum the values.
    const averagePercentage = percentageSum / precisionArray.length; // Get the average.
    const precisionObjects = precisionArray?.map((e) => {
      return e.precisionCheck;
    });
    return { precisionObjects, averagePercentage };
  }, [consultation, grownUps, t]);
}
