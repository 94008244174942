import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import { ConsultationContext, CustomersContext } from "../contexts";
import { updateConsultation } from "../database/consultations";
import InputGroup from "../components/shared/fields/InputGroup";
import StyledForm from "../components/StyledForm";

export default function ChildForm({ child, id, onDelete, close }) {
  const { t } = useTranslation();

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const { consultationID } = useParams();

  if (!consultation) {
    return null;
  }

  const grownUpIDs = consultation?.customers?.grownUps || [];
  const hasPartner = consultation.maritalStatus !== "not-married";

  const childSchema = Yup.object().shape({
    firstName: Yup.string().min(2).required().label(t("First Name")),
    birthDate: Yup.string().required().label(t("Birthday")),
    childOf: Yup.string().required().label(t("Child of")),
  });

  const addChild = async values => {
    const firestore = getFirestore();
    const customersCollection = collection(firestore, "customers");
    const data = {
      ...values,
      type: "child",
      created: serverTimestamp(),
      modified: serverTimestamp(),
    };
    let childRef;
    if (!child) {
      childRef = await addDoc(customersCollection, data);
      await updateConsultation(consultationID, {
        "customers.children": arrayUnion(childRef.id),
      });
    } else {
      childRef = doc(customersCollection, id);
      await updateDoc(childRef, data);
    }

    close();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: "",
        birthDate: "",
        childOf: hasPartner ? "" : grownUpIDs[0],
        ...child,
      }}
      validationSchema={childSchema}
      onSubmit={addChild}
    >
      {({ dirty }) => (
        <StyledForm onDelete={onDelete} dirty={dirty}>
          <InputGroup name="firstName" type="text" label={t("First Name")} />
          <InputGroup label={t("Birthday")} type="date" name="birthDate" />
          <InputGroup label={t("Child of")} as="select" name="childOf">
            <option value="">{t("Please choose")}.</option>
            <option value="both">{t("Both")}</option>
            {grownUpIDs.map(id => (
              <option key={id} value={id}>
                {customers[id]?.firstName}
              </option>
            ))}
          </InputGroup>
        </StyledForm>
      )}
    </Formik>
  );
}
