import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Button from "../../Button";

export default function WizardFooter({ isSubmitting }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center w-full">
      <Button type="button" secondary onClick={() => navigate(-1)}>
        {t("Back")}
      </Button>
      <Button type="submit" disabled={isSubmitting}>
        {t("Next")}
      </Button>
    </div>
  );
}
