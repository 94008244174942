import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import WizardFooter from "../../../../components/shared/wizard/WizardFooter";
import { ConsultationContext } from "../../../../contexts";
import PartnershipForm from "../../../../forms/PartnershipForm";

export default function Partnership() {
  const { t } = useTranslation();
  const consultation = useContext(ConsultationContext);

  const data = {
    maritalStatus: consultation?.maritalStatus,
    weddingDate: consultation?.weddingDate,
  };

  return (
    <div className="max-w-lg mx-auto text-center flex flex-col space-y-4">
      <h1 className="text-3xl">{t("Are you married?")}</h1>
      <PartnershipForm data={data} ActionsComponent={<WizardFooter />} />
    </div>
  );
}
