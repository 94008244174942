export function formatPercentage(number) {
  return new Intl.NumberFormat("de-DE", {
    style: "percent",
    maximumFractionDigits: 0,
  }).format(number);
}

export function formatAsCHF(number) {
  return new Intl.NumberFormat("de-CH", {
    style: "currency",
    maximumFractionDigits: 0,
    currency: "CHF",
  }).format(number);
}

export function getTotalFromPeriodicPayments(periodicPayments) {
  if (!periodicPayments) {
    return 0;
  }
  return periodicPayments.reduce(
    (sum, periodicPayment) =>
      periodicPayment.interval === "month"
        ? (sum += periodicPayment.value)
        : (sum += periodicPayment.value / 12),
    0
  );
}

export function getEstimatedSpending(grossIncome) {
  return (grossIncome / 12) * 0.8;
}
