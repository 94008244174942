import { useFormikContext } from "formik";
import NumberFormat from "react-number-format";

export default function CurrencyField({ field }) {
  const { setFieldValue } = useFormikContext();
  return (
    <NumberFormat
      {...field}
      value={field.value}
      className="w-full"
      onChange={undefined} // Avoid conflicts with custom onValueChange
      onValueChange={(values) => {
        const { floatValue } = values;
        setFieldValue(field.name, floatValue);
      }}
      allowEmptyFormatting
      allowLeadingZeros={false}
      allowNegative={false}
      prefix={"CHF "}
      thousandSeparator={"'"}
      decimalSeparator={false}
      min="0"
      step="1"
    />
  );
}
