import Logo from "./../components/Logo";
import { useTranslation } from "react-i18next";
import Card from "../components/Card";
import Button from "../components/Button";
import { getAuth } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { XIcon } from "@heroicons/react/solid";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts";
import ClosableDialogWithTitle from "../components/shared/dialogs/ClosableDialogWithTitle";
import CreateConsultationButton from "../components/CreateConsultationButton";
import { Toaster, toast } from "react-hot-toast";
import { getCustomer } from "../database/customers";

export default function Packages() {
  const user = useContext(UserContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [dialog, setDialog] = useState(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [customer, setCustomer] = useState({});

  const currentUser = getAuth().currentUser;
  const email = currentUser?.email;
  const isAnonymous = currentUser?.isAnonymous;

  useEffect(() => {
    if (!currentUser?.uid) {
      return;
    }
    getCustomer(currentUser.uid).then(customerData =>
      setCustomer(customerData),
    );
  }, [currentUser?.uid]);

  const { firstName, lastName } = customer;

  const handleStateWhenLoggingIn = () => {
    setLoggingIn(true);
  };

  useEffect(() => {
    if (loggingIn && currentUser?.uid && !isAnonymous) {
      toast("You are logged in");
    }
  }, [currentUser?.uid, isAnonymous, loggingIn]);

  const closeDialog = () => setDialog(null);

  const userParams =
    user &&
    `contact_forename=${user.firstName}&contact_surname=${user.lastName}&contact_email=${email}`;

  const userPlans = [
    {
      id: "pilot",
      name: t("Pilot"),
      description: t(
        "Ideal for people who want to be in the driver seat and understand their financial situation and risks in detail.",
      ),
      benefits: [t("Includes access to Advisory 360 for one year")],
      price: "CHF 199",
      link: `https://advisory360.payrexx.com/de/vpos?amount=199&currency=CHF&purpose=Advisory360 Pilot - Zugriff für 1 Jahr&referenceId=pilot&${userParams}`,
    },
    {
      id: "co-pilot",
      name: t("Co-Pilot"),
      description: t(
        "Ideal for people who value independence and transparent assessment of their financial situation and risk but enjoy the comfort of an expert opinion.",
      ),
      benefits: [
        t("Includes access to Advisory 360 for one year"),
        t("One hour consultation by an independent certified finance expert"),
      ],
      price: "CHF 299",
      link: `https://advisory360.payrexx.com/de/vpos?amount=299&currency=CHF&purpose=Advisory360 Co-Pilot - Zugriff für 1 Jahr&referenceId=copilot&${userParams}`,
    },
    {
      id: "passenger",
      name: t("Passenger"),
      description: t(
        "Ideal for people who look for ongoing expertise of a specialist team while still having direct access to their risk cockpit.",
      ),
      benefits: [
        t("Includes access to Advisory 360 for one year"),
        t(
          "Ongoing advise and support by financial expert from Caveo including consultation (yearly value over CHF 450) and Caveo app access.",
        ),
      ],
      price: t("Free"),
    },
  ];

  return (
    <div className="bg-gray-50 min-h-screen w-screen p-8 flex">
      <div className="container mx-auto flex flex-col space-y-12 text-center items-center">
        {loggingIn && currentUser?.uid && !isAnonymous && (
          <Toaster position="top-center" />
        )}
        <div className="flex absolute top-0 right-0 p-5 ">
          {/*Give the option of a user to sign in if they were logged out*/}
          {!currentUser && (
            <Button
              className="mr-5 cursor-pointer"
              secondary
              onClick={() => {
                handleStateWhenLoggingIn();
                navigate("/login?next=/packages", {
                  state: { backgroundLocation: location },
                });
              }}
            >
              {t("Log in")}
            </Button>
          )}
          <XIcon
            className="h-7 w-7 mr-5 cursor-pointer mt-5"
            onClick={() => navigate(-1)}
          />
        </div>
        <Logo />
        <h1>{t("Choose the right plan for you.")}</h1>
        <p className="text-2xl opacity-50">
          {t("How would you like to hedge your risks?")}
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          {userPlans.map(plan => {
            const planLink = encodeURI(plan.link);
            const next =
              plan.id === "passenger" ? "/validation-code" : planLink;
            return (
              <Card
                key={plan.id}
                className="max-w-sm flex flex-col space-y-4 p-8 h-full"
              >
                <div className="flex-1 flex flex-col space-y-4">
                  <h2>{plan.name}</h2>
                  <p>{plan.description}</p>
                  <ul className="list-disc">
                    {plan.benefits.map(benefit => (
                      <li key={benefit}>{benefit}</li>
                    ))}
                  </ul>
                </div>
                <p className="text-3xl">{plan.price}</p>
                {!user && !isAnonymous && (
                  <CreateConsultationButton
                    label={t("Try for free")}
                    smallFont={true}
                  />
                )}
                {user && (
                  <Button
                    onClick={() => {
                      if (next === "/validation-code") {
                        navigate("/validation-code", {
                          state: { backgroundLocation: location },
                        });
                      } else {
                        window.location.href = plan.link;
                      }
                    }}
                  >
                    {t("Choose package")}
                  </Button>
                )}
                {isAnonymous && (
                  <Button
                    to={`/sign-up?firstName=${firstName}&lastName=${lastName}&next=${next}`}
                    state={{
                      backgroundLocation: location,
                      planLink: planLink,
                      planType: plan.id,
                    }}
                  >
                    {t("Choose package")}
                  </Button>
                )}
              </Card>
            );
          })}
        </div>
      </div>
      <ClosableDialogWithTitle
        close={closeDialog}
        isOpen={!!dialog}
        title={dialog?.title}
      >
        {dialog?.content}
      </ClosableDialogWithTitle>
    </div>
  );
}
