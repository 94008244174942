import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function useCustomerInsurances(customerID) {
  const { consultationID } = useParams();

  const [insurances, setInsurances] = useState([]);

  useEffect(() => {
    const consultationRef = doc(
      getFirestore(),
      "consultations",
      consultationID
    );
    const insuranceCollection = collection(consultationRef, "insurances");
    const q = query(insuranceCollection, where("customer", "==", customerID));
    getDocs(q).then((snapshot) =>
      setInsurances(snapshot.docs.map((doc) => doc.data()))
    );
  }, [consultationID, customerID]);

  return insurances;
}
