import { Dialog, Transition } from "@headlessui/react";

export default function CustomDialog({ children, isOpen, close }) {
  return (
    <Transition appear show={isOpen}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={close}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-20"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black/100" />
          </Transition.Child>
          <Transition.Child>
            <div className="inline-block absolute max-h-full w-full max-w-2xl text-left transition-all bg-white shadow-xl sm:rounded-lg top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
