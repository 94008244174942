import {
  addDoc,
  collection,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { ErrorMessage, Field, Formik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CurrencyField from "../../../../components/CurrencyField";
import StyledForm from "../../../../components/StyledForm";
import PeriodicPaymentInputGroup from "../../../../components/PeriodicPaymentInputGroup";
import InputGroup from "../../../../components/shared/fields/InputGroup";
import {
  ConsultationContext,
  CustomersContext,
  WhitelabelContext,
} from "../../../../contexts";
import StyledErrorMessage from "../../../../components/shared/fields/StyledErrorMessage";
import createAndSaveSolutions from "../../../../utils/createAndSaveSolutions";
import CheckboxInputGroup from "../../../../components/shared/fields/CheckboxInputGroup";
import useCompoundingRates from "../../../../hooks/useCompoundingRates";
import { deleteField } from "firebase/firestore";
import classNames from "classnames";

export default function InsuranceForm({ insurance, onDelete, close }) {
  const { t } = useTranslation();

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);
  const whiteLabeler = useContext(WhitelabelContext);

  const { grownUps } = consultation.customers;
  const { name } = whiteLabeler || {};

  const customerOptions = grownUps.map(id => ({
    value: id,
    label: customers[id].firstName,
  }));

  const compoundingRates = useCompoundingRates();

  const waitingPeriods = [12, 24];

  const risks = [
    {
      label: t("Survival"),
      value: "survival",
    },
    {
      label: t("Invalidity"),
      value: "invalidity",
    },
    {
      label: t("Death"),
      value: "death",
    },
  ];

  const PeriodicPaymentSchema = Yup.object().shape({
    value: Yup.number().required(t("Value is a required field")),
    interval: Yup.string().required(t("Interval is a required field")),
  });

  const InvalidityBenefitsSchema = Yup.object()
    .test(
      "invalidityTest",
      t("Either Sickness pension or Invalidity pension must filled"),
      function () {
        const { from } = this;
        // from is an array of all the nested schema objects starting from the lowest level to the first ancestor
        const insuranceSchema = from[2];
        const invalidityBenefitsSchema = from[0];
        if (insuranceSchema.value.coveredRisks.includes("invalidity")) {
          return [
            invalidityBenefitsSchema.value.accidentPension,
            invalidityBenefitsSchema.value.sicknessPension,
          ].some(field => field && !Object.values(field).includes(undefined));
        } else {
          return true;
        }
      },
    )
    .shape({
      waiverOfPremium: Yup.boolean().required().label(t("Waiver of Premium")),
      waitingPeriod: Yup.number().when("waiverOfPremium", {
        is: true,
        then: Yup.number().required().label("Waiting Period"),
        otherwise: Yup.number().notRequired(),
      }),
      sicknessPension: Yup.object().shape({
        value: Yup.number().moreThan(-1).label(t("Sickness Pension")),
        interval: Yup.string(),
      }),
      accidentPension: Yup.object().shape({
        value: Yup.number().moreThan(-1).label(t("Accident Pension")),
        interval: Yup.string(),
      }),
    });

  const DeathBenefitsSchema = Yup.object()
    .test(
      "deathTest",
      t("Orphan Pension or Guaranteed Capital must be filled"),
      function () {
        const { from } = this;
        // from is an array of all the nested schema objects starting from the lowest level to the first ancestor
        const insuranceSchema = from[2];
        const deathBenefitsSchema = from[0];

        if (insuranceSchema.value.coveredRisks.includes("death")) {
          const hasOrpHanPensionOrGuaranteedCapital = [
            deathBenefitsSchema.value.orphanPension,
            deathBenefitsSchema.value.guaranteedCapital,
          ].some(field => field && !Object.values(field).includes(undefined));
          return hasOrpHanPensionOrGuaranteedCapital;
        } else {
          return true;
        }
      },
    )
    .shape({
      orphanPension: Yup.object().shape({
        value: Yup.number().moreThan(-1).label("Orphan Pension"),
      }),
      guaranteedCapital: Yup.number().when("orphanPension", {
        is: field => field.value === 0,
        then: Yup.number().moreThan(-1).label(t("Guaranteed Capital")),
        otherwise: Yup.number().notRequired(),
      }),
    });

  const SurvivalBenefitsSchema = Yup.object()
    .test("survivalTest", t("This field is required"), function () {
      const { from } = this;
      const insuranceSchema = from[2];
      const survivalBenefitsSchema = from[0];
      if (!insuranceSchema.value.coveredRisks.includes("survival")) {
        return true;
      }
      const { hasGuaranteedCapital, knowsStrategy, assumedReturn } =
        survivalBenefitsSchema.value;
      if (!hasGuaranteedCapital) {
        return false;
      }
      if (hasGuaranteedCapital === "yes") {
        return true;
      }
      if (knowsStrategy === "yes") {
        return assumedReturn;
      }
      return true;
    })
    .shape({
      hasGuaranteedCapital: Yup.string(),
      guaranteedCapital: Yup.number().when("hasGuaranteedCapital", {
        is: "yes",
        then: Yup.number()
          .required()
          .moreThan(-1)
          .label(t("Guaranteed Capital")),
        otherwise: Yup.number().notRequired(),
      }),
      knowsStrategy: Yup.string().when("hasGuaranteedCapital", {
        is: "no",
        then: Yup.string().required().label(t("Investment Strategy")),
        otherwise: Yup.string().notRequired(),
      }),
      assumedReturn: Yup.number(),
    });

  const BenefitsSchema = Yup.object().shape({
    survival: SurvivalBenefitsSchema,
    invalidity: InvalidityBenefitsSchema,
    death: DeathBenefitsSchema,
  });

  const InsuranceSchema = Yup.object().shape({
    customer: Yup.string().required().label(t("Holder")),
    name: Yup.string().required().label(t("Name")),
    start: Yup.date().required().label(t("Start")),
    end: Yup.date()
      .min(Yup.ref("start"), t("End must be after start."))
      .required()
      .label(t("End")),
    premium: PeriodicPaymentSchema.required().label(t("Premium")),
    isBonded: Yup.boolean().required().label(t("Bonded")),
    coveredRisks: Yup.array()
      .of(Yup.string())
      .min(1, t("Your insurance must cover at least one risk"))
      .label(t("Covered Risks")),
    benefits: BenefitsSchema,
  });

  const initialValues = {
    customer: grownUps.length === 1 ? grownUps[0] : "",
    name: "",
    start: "",
    end: "",
    premium: {
      value: "",
      interval: "month",
    },
    coveredRisks: [],
    benefits: {
      survival: {
        hasGuaranteedCapital: "",
        guaranteedCapital: 0,
        knowsStrategy: "",
        assumedReturn: 0,
      },
      invalidity: {
        waitingPeriod: 12,
        waiverOfPremium: false,
        sicknessPension: {
          value: "",
          interval: "month",
        },
        accidentPension: {
          value: "",
          interval: "month",
        },
      },
      death: {
        orphanPension: {
          value: "",
          interval: "month",
        },
        guaranteedCapital: "",
      },
    },
    isBonded: false,
    ...insurance,
  };

  const onSubmit = async values => {
    const firestore = getFirestore();
    const consultationRef = doc(firestore, "consultations", consultation.id);
    const insuranceCollection = collection(consultationRef, "insurances");
    if (insurance) {
      await updateDoc(doc(insuranceCollection, insurance.id), {
        benefits: deleteField(), // clear all benefits to avoid leftovers
        ...values,
      });
    } else {
      await addDoc(insuranceCollection, values);
    }
    await createAndSaveSolutions(consultation.id);
    close();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={InsuranceSchema}
      onSubmit={onSubmit}
    >
      {({ values, dirty }) => (
        <StyledForm onDelete={onDelete} dirty={dirty}>
          {grownUps.length > 1 && (
            <InputGroup
              name={"customer"}
              as="select"
              label={t("Holder")}
              description={t("Who is the holder of this insurance policy?")}
            >
              <option value="">{t("Select holder")}</option>
              {customerOptions.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </InputGroup>
          )}
          <InputGroup
            name={"name"}
            type="text"
            label={t("Name")}
            description={t("What is the name of the insurance product?")}
          />
          <div className="flex space-x-4">
            <InputGroup
              name="start"
              type="date"
              label={t("Start")}
              description={t("When does the insurance coverage start?")}
            />
            <InputGroup
              name="end"
              type="date"
              label={t("End")}
              description={t("When does the insurance coverage end?")}
            />
          </div>
          <PeriodicPaymentInputGroup
            name="premium"
            label={t("Premium")}
            description={t("How much does the insurance cost?")}
          />
          <div className="flex flex-col space-y-2">
            <p>{t("Is your insurance bonded or not?")}</p>
            <CheckboxInputGroup
              name="isBonded"
              label={t("Yes, it's a pillar 3a policy.")}
            />
          </div>
          <label>{t("What does your insurance cover?")}</label>
          <>
            <div className="md:flex md:space-x-4 space-y-3 md:space-y-0">
              {risks.map(({ label, value }) => (
                <label
                  key={value}
                  className="flex-1 flex items-center space-x-2 rounded border p-4"
                >
                  <Field
                    type="checkbox"
                    name="coveredRisks"
                    value={value}
                    className={classNames(
                      whiteLabeler
                        ? `focus:ring-${name}-brand text-${name}-brand`
                        : "focus:ring-brand text-brand",
                      "rounded opacity-50 checked:opacity-100 focus:opacity-100",
                    )}
                  />
                  <span>{label}</span>
                </label>
              ))}
            </div>
            <ErrorMessage name="coveredRisks" render={StyledErrorMessage} />
          </>
          {values.coveredRisks && (
            <>
              {values.coveredRisks.includes("survival") && (
                <>
                  <strong>{t("Benefits in case of survival")}</strong>
                  <InputGroup
                    as="select"
                    name="benefits.survival.hasGuaranteedCapital"
                    label={t("Do you have a guaranteed capital at maturity?")}
                  >
                    <option value="">{t("Please select")}</option>
                    <option value="yes">{t("Yes")}</option>
                    <option value="no">{t("No")}</option>
                  </InputGroup>
                  {values.benefits.survival.hasGuaranteedCapital &&
                    (values.benefits.survival.hasGuaranteedCapital === "yes" ? (
                      <>
                        <label>
                          <InputGroup
                            name="benefits.survival.guaranteedCapital"
                            component={CurrencyField}
                            label={t("Guaranteed Capital")}
                          />
                        </label>
                      </>
                    ) : (
                      <InputGroup
                        as="select"
                        name="benefits.survival.knowsStrategy"
                        label={t(
                          "Do you know the investment strategy of your survival assets?",
                        )}
                      >
                        <option value="">{t("Please select")}</option>
                        <option value="yes">{t("Yes")}</option>
                        <option value="no">{t("No")}</option>
                      </InputGroup>
                    ))}
                  {values.benefits.survival.hasGuaranteedCapital === "no" &&
                    values.benefits.survival.knowsStrategy === "yes" && (
                      <InputGroup
                        as="select"
                        name="benefits.survival.assumedReturn"
                        className="!mb-1"
                        label={t("Investment Strategy")}
                      >
                        <option value="">{t("Please select")}</option>
                        {compoundingRates.map(({ label, value }) => (
                          <option key={label} value={value}>
                            {label}
                          </option>
                        ))}
                      </InputGroup>
                    )}
                  <ErrorMessage
                    name="benefits.survival"
                    render={StyledErrorMessage}
                  />
                </>
              )}
              {values.coveredRisks.includes("invalidity") && (
                <>
                  <strong>{t("Benefits in case of invalidity")}</strong>
                  <label>
                    <Field
                      name="benefits.invalidity.waitingPeriod"
                      className="pr-8"
                      as="select"
                    >
                      {waitingPeriods.map(waitingPeriod => (
                        <option
                          key={waitingPeriod}
                          value={waitingPeriod}
                        >{`${waitingPeriod} ${t("months")}`}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="benefits.invalidity.waitingPeriod"
                      render={StyledErrorMessage}
                    />
                  </label>
                  <PeriodicPaymentInputGroup
                    name="benefits.invalidity.sicknessPension"
                    label={t("Sickness Pension")}
                  />
                  <PeriodicPaymentInputGroup
                    name="benefits.invalidity.accidentPension"
                    label={t("Accident pension")}
                  />
                  <CheckboxInputGroup
                    name="benefits.invalidity.waiverOfPremium"
                    label={t("Includes waiver of premium")}
                  />

                  <ErrorMessage
                    name="benefits.invalidity"
                    render={StyledErrorMessage}
                  />
                </>
              )}
              {values.coveredRisks.includes("death") && (
                <>
                  <strong>{t("Benefits in case of death")}</strong>
                  <PeriodicPaymentInputGroup
                    name="benefits.death.orphanPension"
                    label={t("Orphan pension")}
                  />
                  <InputGroup
                    name="benefits.death.guaranteedCapital"
                    component={CurrencyField}
                    label={t("Guaranteed Capital")}
                    description={t(
                      "How much money do you get for sure in case of death?",
                    )}
                  />
                  <ErrorMessage
                    name="benefits.death"
                    render={StyledErrorMessage}
                  />
                </>
              )}
            </>
          )}
        </StyledForm>
      )}
    </Formik>
  );
}
