import React, { useContext } from "react";
import { formatAsCHF } from "../utils/helpers";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ConsultationContext } from "../contexts";

export default function UnlockedRecommendation({
  type,
  customerID,
  scenarios,
  totals,
  isSingle,
}) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const incomeType =
    useContext(ConsultationContext).budgets[customerID].income.type;

  let recommendation;
  const notEmployedRecommendation =
    incomeType !== "employed"
      ? t(
          "Consider buying a sickness day pay insurance which covers at least 80% of your current gross salary. Pro tip: check the differences between a damage and sum based offerings."
        )
      : null;
  const userScenarios = scenarios.filter((s) => s.customerID === customerID);
  const invalidityScenarios = userScenarios.filter(
    (s) => s.type === "invalidity"
  );
  /*
  // Old average death scenarios calculation
  const deathScenarios = userScenarios.filter((s) => s.type === "death");
  const deathRiskAverage =
     deathScenarios.reduce(
       (sum, scenario) => (sum += scenario.data.totals.cumulative.riskAfter),
       0
     ) / deathScenarios.length
     ;*/
  // Create new array using only the sum & cause from invalidity scenarios
  const basicInvalidityScenarios = invalidityScenarios.map((scenario) => ({
    value: scenario.data.totals.yearly.riskAfter,
    cause: scenario.cause,
  }));

  const formatRiskNumber = formatAsCHF(totals.cumulative.riskAfter);
  const formatInvalidityRiskNumber = formatAsCHF(totals.yearly.riskAfter);
  const formatDeathRiskNumber = formatAsCHF(totals.cumulative.riskAfter); //formatAsCHF(deathRiskAverage)
  if (type === "invalidity") {
    basicInvalidityScenarios.forEach((scenario) => {
      // Scenario calculation is async so the item order in the array is random and the code has to account for that
      const formatSicknessNumber =
        basicInvalidityScenarios[0].cause === "sickness"
          ? formatAsCHF(basicInvalidityScenarios[0].value)
          : formatAsCHF(basicInvalidityScenarios[1].value);
      const formatAccidentNumber =
        basicInvalidityScenarios[0].cause === "accident"
          ? formatAsCHF(basicInvalidityScenarios[0].value)
          : formatAsCHF(basicInvalidityScenarios[1].value);
      if (scenario.cause === "accident" && scenario.value === 0) {
        recommendation = (
          <>
            <Trans i18nKey={"invaliditySicknessRecommendation"}>
              {{ formatInvalidityRiskNumber }}
            </Trans>
            <span className="block">{notEmployedRecommendation}</span>
          </>
        );
      }
      if (scenario.cause === "accident" && scenario.value > 0) {
        recommendation = (
          <>
            <Trans i18nKey={"invalidityAccidentSicknessRecommendation"}>
              {{ formatSicknessNumber }} {{ formatAccidentNumber }}
            </Trans>
            <span className="block">{notEmployedRecommendation}</span>
          </>
        );
      }
    });
  } else if (type === "age") {
    recommendation = (
      <Trans i18nKey={"ageRecommendation"}>{{ formatRiskNumber }}</Trans>
    );
  } else if (type === "death") {
    recommendation = (
      <>
        <Trans i18nKey={"deathRecommendation"}>
          {{ formatDeathRiskNumber }}
        </Trans>
        {isSingle && (
          <p className="pr-4">
            {t(
              "As you are registered as single, we assume that you do not need to provide security for another person. If this is incorrect, please add your persons"
            )}{" "}
            <Link
              to={`/consultations/${consultationID}/people`}
              className="font-bold"
            >
              {t("here")}
            </Link>
            .
          </p>
        )}
      </>
    );
  }

  return <p className="whitespace-pre-wrap">{recommendation}</p>;
}
