import React from "react";
import CreateConsultationButton from "../CreateConsultationButton";
import { useTranslation } from "react-i18next";
// import Risks from "./../../assets/risks.png";

export default function Hero() {
  const { t } = useTranslation();

  return (
    <section className="container mx-auto flex flex-col space-y-8 items-center py-12 text-center">
      <h1 className="text-7xl text-center flex flex-col items-baseline pt-16 pb-12 md:pt-28 md:pb-20">
        {t("Do you have enough money?")}
      </h1>
      {/* <img src={Risks} className="w-7/8" alt={t("Hero")} /> */}
      <p className="text-xl leading-relaxed max-w-md">
        {t(
          "Find out in just three minutes whether you have enough money no matter what life brings to you – or if you might run into financial problems."
        )}
      </p>
      <CreateConsultationButton label={t("Find your risks")} />
    </section>
  );
}
