import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../components/Button";
import useSubscription from "../../../../../../hooks/useSubscription";
import usePrecision from "../../../../../../hooks/usePrecision";
import UnlockedRecommendation from "../../../../../../components/UnlockedRecommendation";
import { Popover } from "@headlessui/react";
import MissingInformation from "../../../../../../components/MissingInformation";
import { Link, useParams } from "react-router-dom";
import {
  ConsultationContext,
  WhitelabelContext,
} from "../../../../../../contexts";
import classNames from "classnames";

export default function Recommendation(props) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const hasSubscription = useSubscription();
  const { averagePercentage } = usePrecision();

  const { grownUps, children } = useContext(ConsultationContext).customers;
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};
  const { totals, type } = props;
  const isSingle = grownUps.length === 1 && !children.length;
  const content = {
    missingData: (
      <div className="flex flex-col space-y-3">
        <p>
          {t(
            "We can’t make a recommendation yet because the risk calculation is based on too many assumptions. Complete your profile to improve the calculations.",
          )}
        </p>
        <Popover className="relative">
          <Popover.Button className={"flex items-center text-left max-w-xs"}>
            <Button>{t("Complete profile")}</Button>
          </Popover.Button>
          <Popover.Panel className="absolute bottom-0 right-0 z-10 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
            <MissingInformation />
          </Popover.Panel>
        </Popover>
      </div>
    ),
    locked: (
      <div className="relative flex flex-col items-center justify-center">
        <div className="blur text-lg">
          <p>
            Ipsa quidem ea ut in dolor fugit ut aspernatur. Ut non nesciunt
            libero ullam. Cum accusamus optio dolorem aliquam quas.
          </p>
          <p>
            Corrupti temporibus vero dolor. Autem ut non quia ipsam quis
            deserunt.
          </p>
        </div>
        <Button className={"absolute"} to={"/packages"}>
          {t("Show recommendation")}
        </Button>
      </div>
    ),
    unlocked:
      totals.cumulative.riskAfter > 0 ? (
        <UnlockedRecommendation isSingle={isSingle} {...props} />
      ) : type === "death" && isSingle ? (
        <p className="pr-4">
          {t(
            "As you are registered as single, we assume that you do not need to provide security for another person. If this is incorrect, please add your persons",
          )}{" "}
          <Link
            to={`/consultations/${consultationID}/people`}
            className="font-bold"
          >
            {t("here")}
          </Link>
          .
        </p>
      ) : (
        <div>{t("No recommendations")}</div>
      ),
  };
  return (
    <div
      className={classNames(
        whiteLabeler
          ? `bg-${name}-brand text-${name}-brand border-${name}-brand border-${name}-width`
          : "bg-grand text-brand  border",
        " bg-opacity-5 rounded-lg relative flex flex-col p-3 space-y-2",
      )}
    >
      <h2 className="text-2xl">{t("Recommendation")}</h2>
      {
        content[
          averagePercentage < 0.8
            ? "missingData"
            : hasSubscription
            ? "unlocked"
            : "locked"
        ]
      }
    </div>
  );
}
