import Overrun from "./Overrun";
import Recommendation from "./Recommendation";
import Risk from "./Risk";
import Solutions from "./Solutions";

export default function Sidebar(props) {
  const { scenarios, type, totals, customerID, cause } = props;

  return (
    <div className="lg:border-r p-4 space-y-4 lg:max-w-md">
      <Risk totals={totals} />
      <Solutions type={type} cause={cause} customerID={customerID} />
      <Overrun totals={totals} />
      <Recommendation
        type={type}
        totals={totals}
        scenarios={scenarios}
        customerID={customerID}
      />
    </div>
  );
}
