import classNames from "classnames";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { WhitelabelContext } from "../contexts";
import Loader from "./Loader";

export default function Button({
  to,
  onClick,
  href,
  children,
  className,
  secondary,
  disabled,
  isLoading,
  ...rest
}) {
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};
  const shared = {
    className: classNames(
      `  flex items-center justify-center px-4 py-2 my-4 font-medium text-center ${
        whiteLabeler ? `bg-${name}-brand rounded-${name}` : "bg-brand rounded"
      }`,
      secondary
        ? `${
            whiteLabeler
              ? `text-${name}-secondary bg-opacity-20 hover:bg-opacity-30`
              : `text-brand bg-opacity-5 hover:bg-opacity-10`
          }`
        : "hover:bg-opacity-90 text-white",
      disabled ? "bg-gray-300 cursor-not-allowed" : "",
      className,
    ),
    ...rest,
  };
  const content = (
    <div className="flex gap-2">
      {isLoading && <Loader size={20} color="white" />}
      {children}
    </div>
  );

  if (href) {
    return (
      <a {...shared} href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  if (onClick) {
    return (
      <button {...shared} onClick={onClick}>
        {children}
      </button>
    );
  }
  if (to) {
    return (
      <Link to={to} {...shared}>
        {content}
      </Link>
    );
  }
  return <button {...shared}>{content}</button>;
}
