import { getAuth } from "firebase/auth";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { ConsultationsContext } from "../contexts";
import Consultations from "../pages/consultations/Consultations";
import ConsultationRoutes from "./ConsultationRoutes";

export default function ConsultationsRoutes() {
  const { consultationID } = useParams();
  const location = useLocation();

  const { backgroundLocation } = location.state || {};

  const [consultations, setConsultations] = useState([]);

  const currentUser = getAuth().currentUser?.uid || undefined;

  useEffect(() => {
    if (currentUser) {
      const db = getFirestore();
      const consultationsRef = collection(db, "consultations");
      const consultationsQuery = query(
        consultationsRef,
        where("user", "==", currentUser),
      );
      const unsubscribe = onSnapshot(consultationsQuery, snapshot =>
        setConsultations(
          snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
        ),
      );
      return unsubscribe;
    }
  }, [currentUser, consultationID]);

  const lastConsultation = consultations.slice(-1)[0];

  return (
    <ConsultationsContext.Provider value={consultations}>
      <Routes>
        <Route path="*">
          <Route
            index
            element={
              lastConsultation ? (
                getAuth()?.currentUser.isAnonymous && backgroundLocation ? (
                  <ConsultationRoutes />
                ) : (
                  <Navigate to={lastConsultation.id} replace />
                )
              ) : (
                <Consultations />
              )
            }
          />
          <Route path=":consultationID/*" element={<ConsultationRoutes />} />
        </Route>
      </Routes>
    </ConsultationsContext.Provider>
  );
}
