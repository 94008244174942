import { getApp } from "firebase/app";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { useMemo } from "react";

export default function useCloudFunctions() {
  const zurichCloudFunctions = useMemo(() => {
    const app = getApp();
    const functions = getFunctions(app, "europe-west6");
    if (process.env.NODE_ENV === "development") {
      connectFunctionsEmulator(functions, "localhost", 5001);
    }
    return functions;
  }, []);

  return zurichCloudFunctions;
}
