import { ErrorMessage, Field } from "formik";
import { useTranslation } from "react-i18next";
import CurrencyField from "./CurrencyField";
import StyledErrorMessage from "./shared/fields/StyledErrorMessage";

export default function PeriodicPaymentInputGroup({
  label,
  description,
  name,
}) {
  const { t } = useTranslation();

  const intervals = [
    {
      label: t("per month"),
      value: "month",
    },
    {
      label: t("per year"),
      value: "year",
    },
  ];

  return (
    <label className="flex flex-col">
      {label && <p>{label}</p>}
      {description && <p className="text-sm opacity-50">{description}</p>}
      <div className="md:flex space-y-1 md:space-x-2">
        <div>
          <Field name={`${name}.value`} component={CurrencyField} />
          <ErrorMessage name={`${name}.value`} render={StyledErrorMessage} />
        </div>
        <div>
          <Field
            name={`${name}.interval`}
            as="select"
            className="pr-8 w-full md:w-auto"
          >
            {intervals.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Field>
          <ErrorMessage name={`${name}.interval`} render={StyledErrorMessage} />
        </div>
      </div>
    </label>
  );
}
