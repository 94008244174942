import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import { getAuth } from "firebase/auth";
import ConsultationsRoutes from "../routes/ConsultationsRoutes";
import Account from "../pages/Account";
import RegisterDialog from "../components/shared/dialogs/RegisterDialog";
import LoginDialog from "../components/LoginDialog";
import Packages from "../pages/Packages";
import about from "../markdown/about.md";
import agb from "../markdown/agb.md";
import contact from "../markdown/contact.md";
import disclaimer from "../markdown/disclaimer.md";
import imprint from "../markdown/imprint.md";
import privacy from "../markdown/privacy.md";
import terms from "../markdown/terms.md";
import StaticPage from "../components/StaticPage";
import { useTranslation } from "react-i18next";
import Wizard from "../pages/Wizard";
import Button from "../components/Button";
import { IntercomProvider } from "react-use-intercom";
import { Helmet } from "react-helmet";
import CaveoVoucher from "../components/CaveoVoucher";
import PassengerForm from "../components/PassengerForm";

export default function AppRoutes() {
  const { t } = useTranslation();
  const location = useLocation();
  const { backgroundLocation } = location.state || {};

  const user = getAuth().currentUser;

  const markdownRoutes = [
    {
      path: "about",
      file: about,
    },
    {
      path: "agb",
      file: agb,
    },
    {
      path: "contact",
      file: contact,
    },
    {
      path: "disclaimer",
      file: disclaimer,
    },
    {
      path: "imprint",
      file: imprint,
    },
    {
      path: "privacy",
      file: privacy,
    },
    {
      path: "terms",
      file: terms,
    },
  ];

  return (
    <IntercomProvider appId="v8ogwr3q">
      <Helmet>
        <title>{t("RiskCockpit")}</title>
        <meta
          name="description"
          content={t(
            "Do you have enough money in case something happens to you? Find out now.",
          )}
        />
      </Helmet>
      <CaveoVoucher />
      <Routes location={backgroundLocation || location}>
        {/* Public routes */}
        <Route path="packages" element={<Packages />} />
        <Route
          path="wizard"
          element={user ? <Navigate to="/consultations" /> : <Wizard />}
        />
        {markdownRoutes.map((markdown, index) => (
          <Route
            key={index}
            path={markdown.path}
            element={<StaticPage file={markdown.file} />}
          />
        ))}
        {/* Home */}
        <Route
          index
          element={user ? <Navigate to="/consultations" replace /> : <Home />}
        />
        {/* App Routes */}
        <Route
          path="consultations/*"
          element={
            user ? <ConsultationsRoutes /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="account"
          element={user ? <Account /> : <Navigate to="/login" replace />}
        />
        {/* If there is no other backgroundLocation for these routes, navigate to the root. */}
        {!backgroundLocation && (
          <>
            <Route path="login" element={<Navigate to="/" replace />} />
            <Route path="sign-up" element={<Navigate to="/" replace />} />
            <Route path="validation-code" element={<PassengerForm />} />
          </>
        )}
        {/* 404 catcher */}
        <Route
          path="*"
          element={
            <div className="h-screen w-screen flex flex-col justify-center items-center space-y-4">
              <h1>404</h1>
              <p>{t("This page could not be found.")}</p>
              <Button to="/">{t("Take me home")}</Button>
            </div>
          }
        />
      </Routes>
      {backgroundLocation && (
        <Routes>
          <Route path="/sign-up" element={<RegisterDialog />} />
          <Route path="/login" element={<LoginDialog />} />
          <Route path="/validation-code" element={<PassengerForm />} />
        </Routes>
      )}
    </IntercomProvider>
  );
}
