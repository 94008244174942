import age from "./age";
import invalidity from "./invalidity";
import death from "./death";

const chartComponents = {
  age,
  invalidity,
  death,
};

export default chartComponents;
