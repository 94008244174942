import { Form } from "formik";
import FormActions from "./FormActions";

export default function StyledForm({
  children,
  ActionsComponent,
  onDelete,
  dirty,
  ...rest
}) {
  return (
    <Form className="flex flex-col space-y-4 px-4" {...rest}>
      {children}
      {ActionsComponent ? (
        ActionsComponent
      ) : (
        <FormActions dirty={dirty} onDelete={onDelete} />
      )}
    </Form>
  );
}
