import { PencilIcon } from "@heroicons/react/solid";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import IncomeForm from "./IncomeForm";
import SpendingsForm from "./SpendingsForm";
import ClosableDialogWithTitle from "../../../../components/shared/dialogs/ClosableDialogWithTitle";
import Card from "../../../../components/Card";
import {
  formatAsCHF,
  getEstimatedSpending,
  getTotalFromPeriodicPayments,
} from "../../../../utils/helpers";
import useCustomerInsurances from "../../../../hooks/useCustomerInsurances";

export default function PersonBudget({ customerID }) {
  const { t } = useTranslation();
  const insurances = useCustomerInsurances(customerID);

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const [visibleDialog, setDialog] = useState(null);
  const [spendingPercentage, setSpendingPercentage] = useState(0.8);

  const customer = customers[customerID];
  const budget = consultation.budgets?.[customerID];
  const thirdPillar = consultation.provisions?.[customerID]?.thirdPillar || [];

  const closeDialog = () => setDialog(null);

  if (!consultation) {
    return null;
  }

  const requirementPerPerson = getEstimatedSpending(
    consultation.budgets[customerID]?.income.gross,
  );
  const income = (budget?.income?.gross || 0) / 12;
  const netIncome = (budget?.income?.net || 0) / 12;
  const budgetSpendings = getTotalFromPeriodicPayments(
    budget?.spendings?.map(spending => ({
      value: parseInt(spending.value),
      interval: spending.interval,
    })),
  );
  const thirdPillarSpendings = getTotalFromPeriodicPayments(
    thirdPillar?.map(asset => asset.deposit),
  );
  const lifeInsuranceSpendings = getTotalFromPeriodicPayments(
    insurances?.map(asset => asset.premium),
  );
  const spendingsTotal =
    budgetSpendings + thirdPillarSpendings + lifeInsuranceSpendings;
  const spendings = spendingsTotal || requirementPerPerson || 0;
  const savingsQuota = ((netIncome - spendings) / income) * 100;

  const cards = [
    {
      id: "income",
      title: t("Gross income per month"),
      value: formatAsCHF(income),
      isEditable: true,
      dialog: {
        title: t("Edit income"),
        content: (
          <IncomeForm
            customerID={customerID}
            spendingPercentage={spendingPercentage}
          />
        ),
      },
    },
    {
      id: "spendings",
      title: budget?.spendings?.length
        ? t("Spendings")
        : t("Estimated spendings"),
      value: formatAsCHF(spendings),
      isEditable: true,
      dialog: {
        title: t("Edit spendings"),
        content: (
          <SpendingsForm
            close={closeDialog}
            customerID={customerID}
            spendingPercentage={spendingPercentage}
            setSpendingPercentage={setSpendingPercentage}
          />
        ),
      },
    },
    {
      id: "savings",
      title: t("Savings Quota"),
      value: parseFloat(savingsQuota).toFixed(1) + "%",
      isEditable: false,
    },
  ];

  return (
    <div className="flex flex-col space-y-4">
      <h2>
        {customer?.firstName} {customer?.lastName}
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {cards.map(({ id, title, value, isEditable, dialog }) => (
          <button
            key={id}
            className="text-left"
            disabled={!isEditable}
            onClick={() => setDialog(dialog)}
          >
            <Card className={"space-y-2"}>
              <div className="flex justify-between items-center">
                <p className="font-medium">
                  <span>{title}</span>{" "}
                  <span className="opacity-50">
                    {id === "savings" ? t("estimated") : t("per month")}
                  </span>
                </p>
                {isEditable && <PencilIcon className="h-6 w-6" />}
              </div>
              <p className="text-5xl">{value}</p>
            </Card>
          </button>
        ))}
      </div>
      <ClosableDialogWithTitle
        isOpen={!!visibleDialog}
        close={closeDialog}
        title={visibleDialog?.title}
      >
        {visibleDialog?.content}
      </ClosableDialogWithTitle>
    </div>
  );
}
