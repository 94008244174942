import { useContext, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import { isVoucherValid } from "../utils/updateProfile";
import { updateUserSubscription } from "../utils/updateProfile";
import { doc, getFirestore } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { UserContext } from "../contexts";

/**
 * Checks if there is a voucher param in the url,
 * decodes the value and updates the user's subscription to passanger
 **/
export default function CaveoVoucher() {
  const location = useLocation();
  const profile = useContext(UserContext);

  const [voucher, setVoucher] = useState("");

  const user = getAuth().currentUser;

  /**
   * Check if there is a voucher search param in the URL and save it to state to be used when the user is logged in.
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedVoucher = decodeURIComponent(searchParams.get("voucher"));
    // When a null value is decoded, it becomes a string
    if (encodedVoucher && encodedVoucher !== "null") {
      setVoucher(encodedVoucher);
      searchParams.delete("voucher");
    }
  }, [location.search, setVoucher]);

  /**
   * Update the user's subscription if the user is logged in, there is a voucher in state, the voucher code is valid and the user's account is not anonymous anymore.
   */
  useEffect(() => {
    if (
      voucher &&
      user &&
      profile &&
      !user.isAnonymous &&
      isVoucherValid(voucher)
    ) {
      const userRef = doc(getFirestore(), "users", user.uid);
      updateUserSubscription(userRef);
    }
  }, [user, voucher, profile]);

  return null;
}
