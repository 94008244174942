import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationContext } from "../../../../contexts";
import GrownUps from "./GrownUps";
import Children from "./Children";

export default function People() {
  const { t } = useTranslation();
  const consultation = useContext(ConsultationContext);

  if (!consultation) {
    return null;
  }

  return (
    <div className="flex flex-col space-y-4">
      <h1>{t("People")}</h1>
      <GrownUps />
      <Children />
    </div>
  );
}
