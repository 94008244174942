import { useTranslation } from "react-i18next";
import { formatAsCHF } from "../../../../../../utils/helpers";

export default function Risk({ totals }) {
  const { t } = useTranslation();

  const { riskAfter } = totals.cumulative;

  if (riskAfter === 0) {
    return (
      <div className="bg-emerald-500 rounded-lg p-4 text-white flex flex-col space-y-1 ">
        <p className="text-4xl font-bold">{t("You have enough money")}</p>
      </div>
    );
  }

  return (
    <div className="bg-red-600 rounded-lg p-4 text-white flex flex-col space-y-1 ">
      <p className="text-2xl font-medium">{t("Risk")}</p>
      <p className="text-5xl font-bold">{formatAsCHF(riskAfter)}</p>
    </div>
  );
}
