import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import PersonCard from "./PersonCard";
import ClosableDialogWithTitle from "../../../../components/shared/dialogs/ClosableDialogWithTitle";
import ChildForm from "../../../../forms/ChildForm";
import AddButton from "../../../../components/AddButton";
import { deleteCustomer } from "../../../../database/customers";

export default function Children() {
  const { t } = useTranslation();

  const [dialog, setDialog] = useState(null);

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const childrenIDs = consultation?.customers?.children || [];
  const children = consultation.customers?.children || [];

  const closeDialog = () => setDialog(null);

  if (!consultation) {
    return null;
  }

  return (
    <>
      <div className="flex">
        <h2 className="mr-4">{t("Children")}</h2>
        <AddButton
          onClick={() =>
            setDialog({
              title: t("Add child"),
              content: <ChildForm close={closeDialog} />,
            })
          }
        />
      </div>
      {childrenIDs.length ? (
        <div className="md:flex space-y-3">
          {children.map((customerID) => {
            const child = customers[customerID];
            if (!child) {
              return null;
            }
            return (
              <PersonCard
                key={customerID}
                person={child}
                onClick={() =>
                  setDialog({
                    title: `${t("Edit")} ${child.firstName}`,
                    content: (
                      <ChildForm
                        child={child}
                        id={customerID}
                        onSubmit={() => setDialog(null)}
                        close={closeDialog}
                        onDelete={() => {
                          deleteCustomer(customerID, consultation.id);
                          closeDialog();
                        }}
                      />
                    ),
                  })
                }
              />
            );
          })}
        </div>
      ) : (
        <p className="opacity-50">
          {t("You don't have any children added yet.")}
        </p>
      )}
      <ClosableDialogWithTitle
        title={dialog?.title}
        close={closeDialog}
        isOpen={!!dialog}
      >
        {dialog?.content}
      </ClosableDialogWithTitle>
    </>
  );
}
