import React, { useContext } from "react";
import { WhitelabelContext } from "../../contexts";
import classNames from "classnames";

export default function Section(props) {
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};
  const { overhead, title, image, description, index } = props;
  return (
    <section
      className={
        index % 2 !== 0 && (whiteLabeler ? `bg-${name}-light` : "bg-brand/20")
      }
    >
      <div className="max-w-6xl mx-auto px-4 py-24 sm:px-6">
        <div className="md:grid md:grid-cols-12 md:gap-6">
          <div
            className={` max-w-xl md:max-w-none md:w-full space-y-4 mx-auto md:col-span-7 lg:col-span-6 md:mt-6 ${
              index % 2 === 0 && "md:order-1"
            }`}
          >
            <p
              className={classNames(
                whiteLabeler ? `text-${name}-brand` : "text-brand",
                "font-semibold text-xl",
              )}
            >
              {overhead}
            </p>
            <h2 className="text-3xl font-extrabold">{title}</h2>
            <p className="max-w-md opacity-50">{description}</p>

            {/*<a*/}
            {/*  href={href || ""}*/}
            {/*  className={classNames(*/}
            {/*    whiteLabeler ? `text-${name}-brand` : "text-brand",*/}
            {/*    "font-medium block",*/}
            {/*  )}*/}
            {/*>*/}
            {/*  {t("Learn more")}*/}
            {/*</a>*/}
          </div>

          <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 ">
            <img src={image} className="max-w-md" alt={title} />
          </div>
        </div>
      </div>
    </section>
  );
}
