import { PlusCircleIcon } from "@heroicons/react/solid";
import { useContext } from "react";
import { WhitelabelContext } from "../contexts";
import classNames from "classnames";

export default function AddButton({ onClick }) {
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};
  return (
    <button onClick={onClick}>
      <PlusCircleIcon
        className={classNames(
          whiteLabeler ? `text-${name}-brand` : "text-brand",
          "h-10 w-10",
        )}
      />
    </button>
  );
}
