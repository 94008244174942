import dataTypes from "../../dataTypes";
import { lines } from "./invalidity";

const sickness = {
  lines,
  areas: [
    dataTypes.occupation.salary,
    dataTypes.firstPillar.death.widow,
    dataTypes.firstPillar.death.orphan,
    dataTypes.secondPillar.bvg.death.widow,
    dataTypes.secondPillar.bvg.death.orphan,
    dataTypes.solutions,
    dataTypes.situation.uncovered,
  ],
};

const accident = {
  lines,
  areas: [
    dataTypes.occupation.salary,
    dataTypes.firstPillar.death.widow,
    dataTypes.firstPillar.death.orphan,
    dataTypes.secondPillar.uvg.widow,
    dataTypes.secondPillar.uvg.orphan,
    dataTypes.secondPillar.bvg.supplemental.main,
    dataTypes.secondPillar.bvg.supplemental.orphan,
    dataTypes.solutions,
    dataTypes.situation.uncovered,
  ],
};

const deathComponents = {
  sickness,
  accident,
};

export default deathComponents;
