import { XIcon } from "@heroicons/react/solid";
import CustomDialog from "./Dialog";

export default function ClosableDialogWithTitle({
  title,
  children,
  isOpen,
  close,
}) {
  return (
    <CustomDialog isOpen={isOpen} close={close}>
      <div className="flex flex-col max-h-screen bg-gray-50 rounded-lg">
        <div className="flex justify-between p-4 rounded-t border-b">
          <h3 className="font-medium">{title}</h3>
          <button type="button" tabIndex="-1" onClick={close}>
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="flex-1 overflow-auto p-5">{children}</div>
      </div>
    </CustomDialog>
  );
}
