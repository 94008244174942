import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/landing/Header";
import Footer from "../components/landing/Footer";
import Button from "../components/Button";
import { LibraryIcon, OfficeBuildingIcon } from "@heroicons/react/outline";
import Section from "../components/landing/Section";
import Hero from "../components/landing/Hero";
import Gaps from "./../assets/gaps.png";
import Green from "./../assets/green.png";
import Family from "./../assets/family.png";
import { WhitelabelContext } from "../contexts";
import classNames from "classnames";
import Newsletter from "../components/Newsletter";

export default function Home() {
  const { t } = useTranslation();
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};

  const benefits = [
    {
      title: t("Die Analyse – Risiken visualisieren"),
      description: t(
        "Erstelle in wenigen Minuten deine personalisierte Vorsorgeanalyse. Die einfachen und verständlichen Grafiken zeigen Vorsorgelücken in den Bereichen Altersvorsorge, Invalidität und Todesfall auf. ",
      ),
      image: Green,
    },
    {
      title: t("Die Lösungsfindung – Risiken absichern"),
      description: t(
        "Schliesse deine ermittelten Lücken mit den passgenauen Lösungen - digital & effizient. Bei Bedarf helfen wir dir gerne bei der Suche nach den fairsten Lösungen.",
      ),
      image: Gaps,
    },
    {
      title: t("Lebensplanung – passe deine Lösungen deinen Bedürfnissen an"),
      description: t(
        "Aus Erfahrung können wir dir sagen: Vorsorge endet nicht mit der ersten Analyse oder nach dem Kauf eines Produkts, sondern passt sich stetig an deine ändernden Lebenssituationen an. Die advisory360-Vorsorgeanalyse gibt dir in jeder Lebensphase die nötige Entscheidungshilfe, wann es sinnvoll ist, deinen Versicherungsschutz oder Sparprozess an relevante Lebensereignisse anzupassen - flexibel & dynamisch.",
      ),
      image: Family,
    },
  ];

  return (
    <div className="flex flex-col min-h-screen ">
      <Header />
      <Hero />
      <section className="container mx-auto py-12 md:grid md:grid-cols-12 my-8 md:gap-6">
        <div className="max-w-xl md:max-w-none md:w-full space-y-4 mx-auto md:col-span-7 lg:col-span-6 md:mt-6 shadow-xl border mb-8 md:mb-0 p-12">
          <OfficeBuildingIcon
            className={classNames(
              whiteLabeler ? `text-${name}-brand` : "text-brand",
              "w-8 ",
            )}
          />
          <h2 className="text-3xl font-extrabold">{t("For Companies")}</h2>
          <p className="my-10 max-w-md opacity-50">
            {t(
              "Make your employees feel comfortable by helping them to reduce incertainties about their future. With their personalized risk assessment they can identify and address potential risks timely and adequately.",
            )}
          </p>
          <p></p>
          <Button
            href={
              "https://caveoag.pipedrive.com/scheduler/2rBrrEuQ/beratung-mit-philippe-muntwyler"
            }
          >
            {t("Book appointment")}
          </Button>
        </div>
        <div className="max-w-xl md:max-w-none md:w-full space-y-4 mx-auto md:col-span-7 lg:col-span-6 md:mt-6 shadow-xl border p-12">
          <LibraryIcon
            className={classNames(
              whiteLabeler ? `text-${name}-brand` : "text-brand",
              "w-8 ",
            )}
          />
          <h2 className="text-3xl font-extrabold">{t("For Pension Funds")}</h2>
          <p className="my-10 max-w-md opacity-50">
            {t(
              "Provide your destinataires with compelling graphs showing your performance and benefits for their individual situation. Help your destinataires to assess their personal risk situation with alomst no efforts but a very high precision.",
            )}
          </p>
          <p></p>
          <Button
            href={
              "https://caveoag.pipedrive.com/scheduler/2rBrrEuQ/beratung-mit-philippe-muntwyler"
            }
          >
            {t("Book appointment")}
          </Button>
        </div>
      </section>
      <section
        className={classNames(
          whiteLabeler ? `bg-${name}-light` : "bg-brand/20",
          "my-10",
        )}
      >
        <div
          className={classNames(
            whiteLabeler ? `max-w-3xl` : "container",
            " mx-auto flex flex-col space-y-8 items-center py-24 text-center p-6",
          )}
        >
          <h2 className="text-5xl">
            {t(
              "Wenig Ahnung von Vorsorge und noch weniger Lust auf einen Vorsorgeverkäufer? Wir verstehen dich.",
            )}
          </h2>
          <p className={classNames(whiteLabeler ? `` : "max-w-md")}>
            {t(
              "Vorsorge musss nicht langweilig, kompliziert und Oldschool sein. Unser Prozess ist vollständig digital, intuitiv und transparent. Wir bieten dir eine hochpräzise Vorsorgeanalyse und Deckungslösungen in kürzester Zeit – so macht Vorsorge Spass: endlich verständlich und nachvollziehbar.",
            )}
          </p>
        </div>
      </section>
      {benefits.map((props, index) => (
        <Section key={index} index={index} {...props} />
      ))}
      <Newsletter />
      <Footer />
    </div>
  );
}
