import Card from "../../../../components/Card";
import PersonIcon from "../../../../components/PersonIcon";

export default function PersonCard({ person, onClick }) {
  return (
    <button onClick={onClick}>
      <Card className={"p-12"}>
        <PersonIcon gender={person.gender} type={person.type} />
        <p className="mt-4 text-xl">
          {person.firstName} {person.lastName}
        </p>
      </Card>
    </button>
  );
}
