import { useEffect, useMemo, useContext, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import Loader from "../../../../components/Loader";
import Button from "../../../../components/Button";
import {
  CheckCircleIcon,
  ExclamationIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import { getAuth } from "firebase/auth";
import useCloudFunctions from "../../../../hooks/useCloudFunctions";

export default function RiskAssessmentCard({
  customerID,
  label,
  type,
  cause,
  onClick,
  setScenariosArray,
}) {
  const { consultationID } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const cloudFunctions = useCloudFunctions();

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const [results, setResults] = useState(null);

  const grownUps = consultation?.customers.grownUps || [];
  const customer = customers?.[grownUps?.[0]] || {};
  const { firstName = "", lastName = "" } = customer;

  /**
   * Calculate the scenario.
   */
  useEffect(() => {
    const getData = httpsCallable(cloudFunctions, "calculateScenario");
    getData({
      consultationID,
      type,
      cause,
      customerID,
    }).then(({ data }) => {
      setResults(data);
      setScenariosArray((prevState) => [
        ...prevState,
        { data, customerID, type, cause },
      ]);
    });
  }, [
    type,
    cause,
    customerID,
    consultationID,
    setScenariosArray,
    cloudFunctions,
  ]);

  /**
   * Get assessment
   */
  const assessment = useMemo(() => {
    if (!results?.totals) {
      return {};
    }
    const { uncovered } = results.totals.cumulative;
    if (uncovered > 100000) {
      return {
        icon: <ExclamationIcon className="h-16 w-16 text-red-400" />,
        label: (
          <span className="font-medium text-red-500">
            {t("You have a big risk.")}
          </span>
        ),
      };
    }
    if (uncovered > 0) {
      return {
        icon: <QuestionMarkCircleIcon className="h-16 w-16 text-yellow-400" />,
        label: (
          <span className="font-medium text-yellow-500">
            {t("You have a moderate risk.")}
          </span>
        ),
      };
    }
    return {
      icon: <CheckCircleIcon className="h-16 w-16 text-emerald-400" />,
      label: (
        <span className="font-medium text-emerald-500">
          {t("You have enough money")}.
        </span>
      ),
    };
  }, [results, t]);

  const linkProps = {
    disabled: !results,
    ...(getAuth()?.currentUser.isAnonymous
      ? {
          to: `/sign-up?firstName=${firstName}&lastName=${lastName}`,
          state: { backgroundLocation: location },
        }
      : {
          onClick: () => onClick({ customerID, type, cause, data: results }),
          to: "",
        }),
  };

  return (
    <Link
      {...linkProps}
      className="bg-white rounded-lg shadow-lg p-4 flex flex-col space-y-4 items-center justify-between"
    >
      <h3 className="text-xl text-center">{label}</h3>
      {results ? assessment.icon : <Loader className="h-16 w-16" />}
      <p>
        {results ? (
          results.error ? (
            t("Could not calculate.")
          ) : (
            assessment.label
          )
        ) : (
          <span className="opacity-50">{t("Calculating...")}</span>
        )}
      </p>
      <Button secondary className="w-full">
        {t("Show scenario")}
      </Button>
    </Link>
  );
}
