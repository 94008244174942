import React, { useContext } from "react";
import { Form, Formik } from "formik";
import Button from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import { updateConsultation } from "../../../../database/consultations";
import { useParams } from "react-router-dom";
import { ConsultationContext } from "../../../../contexts";
import * as Yup from "yup";
import InputGroup from "../../../../components/shared/fields/InputGroup";
import CurrencyField from "../../../../components/CurrencyField";

export default function FirstPillarForm({ customer, close }) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const consultation = useContext(ConsultationContext);
  let firstPillar = "";

  if (consultation.provisions) {
    if (Object.keys(consultation.provisions)[0] === customer) {
      firstPillar = Object.values(consultation.provisions)[0].firstPillar;
    } else if (Object.keys(consultation.provisions)[1] === customer) {
      firstPillar = Object.values(consultation.provisions)[1].firstPillar;
    }
  }

  const pillarSchema = Yup.object().shape({
    total: Yup.string().required(t("Required")),
    missingYears: Yup.string().required(t("Required")),
  });

  const onSubmit = async values => {
    await updateConsultation(consultationID, {
      [`provisions.${customer}.firstPillar`]: {
        ...values,
        hasSummary: true,
      },
    });
    close();
  };

  return (
    <Formik
      initialValues={{
        total: "",
        missingYears: "",
        ...firstPillar,
      }}
      validationSchema={pillarSchema}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form className="space-y-2">
          <p className="text-lg text-gray-500">
            {t(
              "You’ll find all the data to fill out the form below in your AHV excerpt. You don’t have that? Request it",
            )}
            <a
              href={
                "https://www.ahv-iv.ch/de/Merkbl%C3%A4tter-Formulare/Bestellung-Kontoauszug"
              }
              rel="noreferrer"
              target="_blank"
            >
              {t("here")}
            </a>
          </p>
          <InputGroup
            component={CurrencyField}
            name="total"
            description={t("Total income according to AHV excerpt")}
          />
          <InputGroup
            name="missingYears"
            type="number"
            description={t("Number of missing years")}
            className="form-input w-full h-14 hover:appearance-none"
          />
          <Button disabled={!dirty} type="submit" className="mt-3">
            {t("Save")}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
