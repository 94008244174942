import classNames from "classnames";

export default function Card({ children, className, ...rest }) {
  return (
    <div
      className={classNames("bg-white rounded-lg shadow p-4 mr-4", className)}
      {...rest}
    >
      {children}
    </div>
  );
}
