import {
  addDoc,
  doc,
  updateDoc,
  getFirestore,
  serverTimestamp,
  collection,
} from "firebase/firestore";

export async function updateConsultation(consultationID, data) {
  const consultationRef = doc(getFirestore(), "consultations", consultationID);
  await updateDoc(consultationRef, {
    ...data,
    modified: serverTimestamp(),
  });
}

export async function createConsultation(userID) {
  const firestore = getFirestore();
  const ref = collection(firestore, "consultations");
  return addDoc(ref, {
    created: new Date(),
    user: userID,
  });
}
