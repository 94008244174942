import dataTypes from "../../dataTypes";

const sharedAreas = [
  dataTypes.occupation.salary,
  dataTypes.occupation.LFZMIN,
  dataTypes.occupation.LFZMAX,
  dataTypes.occupation.KTG,
];

export const lines = [
  dataTypes.occupation.previousNetIncome,
  dataTypes.occupation.minRequirement,
];

const sickness = {
  lines,
  areas: [
    ...sharedAreas,
    dataTypes.firstPillar.invalidity.main,
    dataTypes.firstPillar.invalidity.children,
    dataTypes.secondPillar.bvg.invalidity.main,
    dataTypes.secondPillar.bvg.invalidity.children,
    dataTypes.secondPillar.bvg.supplemental.main,
    dataTypes.secondPillar.bvg.supplemental.children,
    dataTypes.solutions,
    dataTypes.situation.uncovered,
  ],
};

const accident = {
  lines,
  areas: [
    ...sharedAreas,
    dataTypes.firstPillar.invalidity.main,
    dataTypes.firstPillar.invalidity.children,
    dataTypes.secondPillar.uvg.UVG,
    dataTypes.secondPillar.uvg.daily,
    dataTypes.secondPillar.bvg.invalidity.main,
    dataTypes.secondPillar.bvg.invalidity.children,
    dataTypes.secondPillar.bvg.supplemental.main,
    dataTypes.secondPillar.bvg.supplemental.children,
    dataTypes.solutions,
    dataTypes.situation.uncovered,
  ],
};

const invalidity = {
  sickness,
  accident,
};

export default invalidity;
