import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { UserContext, WhitelabelContext } from "./contexts";
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import SplashScreen from "./components/SplashScreen";
import AppRoutes from "./routes/AppRoutes";

const firebaseConfig = {
  apiKey: "AIzaSyC3dVRlbc8QJg1NdXTAL5O29b5Bw2Vuyuk",
  authDomain: "advisory360.firebaseapp.com",
  projectId: "advisory360",
  storageBucket: "advisory360.appspot.com",
  messagingSenderId: "884406571958",
  appId: "1:884406571958:web:9e3c68076ac307251ee717",
};

const app = initializeApp(firebaseConfig);

export const functions = getFunctions(app, "europe-west6");

export default function App() {
  const [user, setUser] = useState();
  const [profile, setProfile] = useState();
  const [whiteLabeler, setWhiteLabeler] = useState(undefined);

  const userID = getAuth().currentUser?.uid;
  const whiteLabelerName = window.location.pathname.split("/")[1]; // Get whiteLabeler name from the url

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (userID) {
      const ref = doc(getFirestore(), "users", getAuth().currentUser.uid);
      return onSnapshot(ref, snapshot => setProfile(snapshot.data()));
    } else {
      setProfile(null);
    }
  }, [userID]);

  /**
   * Check if we have a whitelabel customer with this name.
   */
  useEffect(() => {
    if (whiteLabelerName.length) {
      try {
        const whiteLabelerRef = doc(
          getFirestore(),
          "whitelabelers",
          whiteLabelerName,
        );
        getDoc(whiteLabelerRef).then(res => {
          setWhiteLabeler(res.data() || null);
        });
      } catch (e) {
        console.error(e);
      }
    } else {
      setWhiteLabeler("");
    }
  }, [whiteLabelerName]);

  // Wait until we have tried to log the user in and checked if there is a whitelabeler:
  if (user === undefined || whiteLabeler === undefined) {
    return <SplashScreen />;
  }

  return (
    <BrowserRouter basename={whiteLabeler?.path || "/"}>
      <WhitelabelContext.Provider value={whiteLabeler}>
        <UserContext.Provider value={profile}>
          <AppRoutes />
        </UserContext.Provider>
      </WhitelabelContext.Provider>
    </BrowserRouter>
  );
}
