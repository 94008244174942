import React from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { useParams } from "react-router-dom";

export default function NoGrownUps() {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  return (
    <div>
      <p className="opacity-50 mb-4">
        {t("You don't have any grown ups added yet.")}
      </p>
      <Button to={`/consultations/${consultationID}/wizard/person`}>
        {t("Complete Wizard")}
      </Button>
    </div>
  );
}
