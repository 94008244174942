import React, { useContext } from "react";
import { FieldArray, Form, Formik } from "formik";
import Button from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import InputGroup from "../../../../components/shared/fields/InputGroup";
import { ShieldCheckIcon, XCircleIcon } from "@heroicons/react/solid";
import { updateConsultation } from "../../../../database/consultations";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { ConsultationContext } from "../../../../contexts";
import CurrencyField from "../../../../components/CurrencyField";
import PeriodicPaymentInputGroup from "../../../../components/PeriodicPaymentInputGroup";
import createAndSaveSolutions from "../../../../utils/createAndSaveSolutions";
import useCompoundingRates from "../../../../hooks/useCompoundingRates";

export default function ThirdPillarForm({ customer, close, insurances }) {
  const { t } = useTranslation();
  const { consultationID } = useParams();

  const consultation = useContext(ConsultationContext);

  const thirdPillar = consultation.provisions?.[customer]?.thirdPillar || [];

  const compoundingRates = useCompoundingRates();

  const assetSchema = Yup.object().shape({
    description: Yup.string().required(t("Required")),
    currentValue: Yup.string().required(t("Required")),
    deposit: Yup.object().shape({
      value: Yup.number().required(t("Value is a required field")),
      interval: Yup.string().required(t("Interval is a required field")),
    }),
    knowsStrategy: Yup.string(),
    assumedReturn: Yup.number(),
  });

  const assetsSchema = Yup.object().shape({
    assets: Yup.array().of(assetSchema),
  });

  const onSubmit = async values => {
    try {
      const { assets } = values;
      const data = {
        [`provisions.${customer}.thirdPillar`]: assets.length ? assets : 0,
      };
      await updateConsultation(consultationID, data);
      await createAndSaveSolutions(consultationID);
      close();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        assets: thirdPillar || [
          {
            description: "",
            currentValue: "",
            deposit: { value: "", interval: "month" },
          },
        ],
      }}
      validationSchema={assetsSchema}
      onSubmit={onSubmit}
    >
      {({ values, dirty }) => (
        <Form className="space-y-4">
          <p className="text-lg text-gray-500">
            {t(
              "Add all your assets of free and bonded private provisions (Pillars 3a and 3b).",
            )}
          </p>
          {insurances &&
            insurances.map(insurance => (
              <div className="w-full relative justify-between items-center flex  p-4 border rounded bg-white">
                <p className="flex space-x-2">
                  <ShieldCheckIcon className="w-5 h-5 text-green-400" />
                  <span>{insurance.name}</span>
                </p>
                <Button to={`/consultations/${consultationID}/insurances`}>
                  {t("Edit")}
                </Button>
              </div>
            ))}
          <FieldArray
            name="assets"
            render={arrayHelpers => (
              <div className="flex flex-col space-y-4">
                {values.assets &&
                  values.assets.map((asset, index) => (
                    <div
                      key={index}
                      className="w-full relative flex flex-col space-y-1 p-4 border rounded bg-white"
                    >
                      <h2>{`${t("Asset_3P")} ${index + 1}`}</h2>
                      <InputGroup
                        name={`assets.${index}.description`}
                        type="text"
                        description={t("Description")}
                      />
                      <InputGroup
                        description={t("Current value")}
                        component={CurrencyField}
                        type="number"
                        name={`assets.${index}.currentValue`}
                      />
                      <PeriodicPaymentInputGroup
                        description={t("Deposits")}
                        name={`assets.${index}.deposit`}
                      />
                      <InputGroup
                        as="select"
                        name={`assets.${index}.knowsStrategy`}
                        label={t(
                          "Do you know the investment strategy of your survival assets?",
                        )}
                      >
                        <option value="">{t("Please select")}</option>
                        <option value="yes">{t("Yes")}</option>
                        <option value="no">{t("No")}</option>
                      </InputGroup>
                      {values.assets[index].knowsStrategy === "yes" && (
                        <InputGroup
                          as="select"
                          name={`assets.${index}.assumedReturn`}
                          className="!mb-1"
                          label={t("Investment Strategy")}
                        >
                          <option value="">{t("Please select")}</option>
                          {compoundingRates.map(({ label, value }) => (
                            <option key={label} value={value}>
                              {label}
                            </option>
                          ))}
                        </InputGroup>
                      )}

                      <button
                        type="button"
                        className="absolute -top-3 -right-3 text-red-500"
                        onClick={() => arrayHelpers.remove(index)} // remove an asset from the list
                      >
                        <XCircleIcon className="h-6 w-6" />
                      </button>
                    </div>
                  ))}
                <Button
                  secondary
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      description: "",
                      currentValue: "",
                      deposit: { value: "", interval: "month" },
                    })
                  }
                  className="self-start my-4"
                >
                  {t("Add asset")}
                </Button>
                {/*Give the user the option to remove third assets completely*/}
                {(arrayHelpers.form.values.assets.length ||
                  thirdPillar.length > 0) && (
                  <Button disabled={!dirty} type="submit">
                    {t("Save")}
                  </Button>
                )}
              </div>
            )}
          />
        </Form>
      )}
    </Formik>
  );
}
