import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { WhitelabelContext } from "../contexts";

export default function Logo({ orientation }) {
  const { t } = useTranslation();
  const whiteLabeler = useContext(WhitelabelContext);

  const { src, name } = whiteLabeler?.logo || {};
  return whiteLabeler ? (
    <>
      {src ? (
        <img src={src} alt={name} className="w-36 mx-auto" />
      ) : (
        <span className="opacity-80 text-2xl font-semibold tracking-tight">
          {name}
        </span>
      )}
    </>
  ) : (
    <div
      className={
        orientation === "vertical"
          ? "space-y-2 flex flex-col items-center"
          : "flex space-x-2 items-center"
      }
    >
      <div className="overflow-hidden flex space-x-1">
        {["bg-red-400", "bg-amber-400", "bg-emerald-400"].map(background => (
          <div key={background} className={`${background} h-5 w-5`} />
        ))}
      </div>
      <span className="opacity-80 text-2xl font-semibold tracking-tight">
        {t("RiskCockpit")}
      </span>
    </div>
  );
}
