import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FieldArray, Form, Formik } from "formik";
import WizardFooter from "../../../../components/shared/wizard/WizardFooter";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import InputGroup from "../../../../components/shared/fields/InputGroup";
import { XCircleIcon } from "@heroicons/react/solid";
import Button from "../../../../components/Button";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";
import { updateConsultation } from "../../../../database/consultations";

export default function Children() {
  const { t } = useTranslation();

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const childrenIDs = consultation?.customers?.children || [];
  const grownUpIDs = consultation?.customers?.grownUps || [];

  const childrenData = childrenIDs.map(id => customers[id]);
  const grownUpsData = grownUpIDs.map(id => customers[id]);

  const navigate = useNavigate();

  const { consultationID } = useParams();

  const childrenSchema = Yup.object().shape({
    children: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().min(2).required().label(t("First Name")),
        birthDate: Yup.string().required().label(t("Birthday")),
        childOf: Yup.string().required().label(t("Child of")),
      }),
    ),
  });

  const onSubmit = async values => {
    const { children } = values;
    const firestore = getFirestore();
    // Delete children:
    const deletedChildrenIDs = childrenIDs.filter(
      id => !children.find(child => child.id === id),
    );
    await Promise.all(
      deletedChildrenIDs.map(id => deleteDoc(doc(firestore, "customers", id))),
    );
    // Update or create children:
    const newChildrenRefs = await Promise.all(
      children.map(child => {
        if (child.id) {
          const ref = doc("customers", child.id);
          return updateDoc(ref, child);
        } else {
          return addDoc(collection(firestore, "customers"), child);
        }
      }),
    );
    // Set children IDs in consultation
    await updateConsultation(consultationID, {
      "customers.children": newChildrenRefs.map(ref => ref.id),
    });
    navigate(`/consultations/${consultationID}/wizard/income`);
  };

  return (
    <div className="max-w-xl mx-auto text-center flex flex-col space-y-4">
      <Formik
        enableReinitialize
        initialValues={{
          children: childrenData,
        }}
        validationSchema={childrenSchema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className="flex flex-col items-center space-y-8 p-4">
            <h1>{t("Do you have any children?")}</h1>
            <p>{t("Add all your children and those of your partner.")}</p>
            <FieldArray
              name="children"
              render={arrayHelpers => (
                <div className="flex flex-col">
                  <div className="flex flex-wrap">
                    {values.children &&
                      values.children.length > 0 &&
                      values.children.map((child, index) => (
                        <div
                          key={index}
                          className="w-60 p-4 m-4 bg-white shadow rounded relative flex flex-col space-y-4"
                        >
                          <InputGroup
                            name={`children.${index}.firstName`}
                            type="text"
                            label={t("First Name")}
                          />
                          <InputGroup
                            label={t("Birthday")}
                            type="date"
                            name={`children.${index}.birthDate`}
                          />
                          <InputGroup
                            label={t("Child of")}
                            as="select"
                            name={`children.${index}.childOf`}
                          >
                            <option value="">{t("Please choose.")}</option>
                            <option value={grownUpIDs[0]}>
                              {grownUpsData[0]?.firstName}
                            </option>
                            {grownUpIDs[1] && (
                              <>
                                <option value={grownUpIDs[1]}>
                                  {grownUpsData[1]?.firstName}
                                </option>
                                <option value="both">{t("Both")}</option>
                              </>
                            )}
                          </InputGroup>
                          <button
                            type="button"
                            className="absolute -top-8 -right-4 text-red-500"
                            onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                          >
                            <XCircleIcon className="h-8 w-8" />
                          </button>
                        </div>
                      ))}
                  </div>
                  <Button
                    secondary
                    onClick={() =>
                      arrayHelpers.push({
                        firstName: "",
                        birthDate: "",
                        childOf: "",
                        type: "child",
                      })
                    }
                    className="self-center"
                  >
                    {t("Add child")}
                  </Button>
                </div>
              )}
            />
            <WizardFooter />
          </Form>
        )}
      </Formik>
    </div>
  );
}
