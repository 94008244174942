import React, { useContext } from "react";
import WizardFooter from "../../../../components/shared/wizard/WizardFooter";
import { useTranslation } from "react-i18next";
import { Form, Formik, FieldArray } from "formik";
import { updateConsultation } from "../../../../database/consultations";
import * as Yup from "yup";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import { useNavigate, useParams } from "react-router-dom";
import CurrencyField from "../../../../components/CurrencyField";
import InputGroup from "../../../../components/shared/fields/InputGroup";
import { differenceInCalendarYears, parseISO } from "date-fns";
import { getEstimatedSpending } from "../../../../utils/helpers";

export default function Income() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { consultationID } = useParams();

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  if (!consultation || !customers) {
    return null;
  }

  const { grownUps: grownUpIDs } = consultation?.customers || {};
  const grownUps = grownUpIDs.map(id => customers[id]);

  const incomes = grownUpIDs.map(id => {
    const customerBudget = consultation.budgets?.[id] || {};
    const { gross, type } = customerBudget?.income || {};
    return {
      gross: gross || "",
      employmentStatus: type || "",
    };
  });

  const incomeSchema = Yup.object().shape({
    employmentStatus: Yup.string().required(t("Employment status is required")),
    gross: Yup.number().when("employmentStatus", {
      is: "not-employed",
      then: Yup.number().notRequired(),
      otherwise: Yup.number().required(t("Gross income is required")),
    }),
  });

  const schema = Yup.object().shape({
    incomes: Yup.array().of(incomeSchema).required(),
  });

  const calculateNetIncome = (age, grossIncome) => {
    const baseCost = 0.064; // base cost is 6.4% of gross income
    if (age < 35) {
      return grossIncome - grossIncome * (baseCost + 0.035);
    }
    if (age >= 35 && age < 45) {
      return grossIncome - grossIncome * (baseCost + 0.05);
    }
    if (age >= 45 && age < 55) {
      return grossIncome - grossIncome * (baseCost + 0.075);
    }
    if (age >= 55) {
      return grossIncome - grossIncome * (baseCost + 0.09);
    }
  };

  const onSubmit = async values => {
    const currentDate = new Date();
    const { incomes } = values;
    const data = {};
    // Add the income data per customer:
    grownUpIDs.forEach((id, index) => {
      const age = differenceInCalendarYears(
        currentDate,
        parseISO(customers[id].birthDate),
      );
      const valuesForCustomer = incomes[index];
      const grossIncome =
        valuesForCustomer.employmentStatus === "not-employed"
          ? 0
          : valuesForCustomer.gross;
      data[`budgets.${id}.income.net`] =
        valuesForCustomer.employmentStatus === "self-employed"
          ? grossIncome - 0.1 * grossIncome
          : calculateNetIncome(age, grossIncome);
      data[`budgets.${id}.income.gross`] = grossIncome;
      data[`budgets.${id}.income.type`] = valuesForCustomer.employmentStatus;
      // Add default values for provisions so that the calculator works:
      if (!consultation.provisions?.[id]) {
        data[`provisions.${id}`] = {
          firstPillar: {
            hasSummary: false,
          },
          secondPillar: {
            hasSummary: false,
          },
          thirdPillar: 0,
        };
      }
    });
    // Calculate a default spending requirement:
    const requirements = grownUpIDs.map((id, index) => {
      const definedSpendings = consultation.budgets?.[id]?.spendings;
      let requirement;
      // A user's already defined requirements shouldn't be updated when they add a partner
      if (definedSpendings) {
        requirement = definedSpendings.reduce((sum, spending) => {
          sum += parseInt(spending.value);
          return sum;
        }, 0);
      } else {
        requirement = getEstimatedSpending(incomes[index].gross);
      }
      return requirement;
    });
    const combinedRequirements = requirements.reduce((sum, requirement) => {
      sum += requirement;
      return sum;
    }, 0);
    // Save a default spending requirement. Assumption: People use 80% of their income.
    data["budgets.requirements"] = combinedRequirements;
    await updateConsultation(consultationID, data);
    navigate(`/consultations/${consultationID}/risks`);
  };

  return (
    <>
      <div className="text-center">
        <h1 className="mb-10">{t("How much do you earn?")}</h1>
        <p className="mb-20">
          {t("We need this to know how much you can save per month.")}
        </p>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          incomes: incomes || [],
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className="max-w-xl mx-auto px-2">
            <div className="flex pb-10 justify-center">
              <FieldArray
                name="incomes"
                render={() =>
                  grownUps.map((grownUp, index) => (
                    <div
                      key={index}
                      className="w-60 p-4 m-4 bg-white shadow rounded relative flex flex-col space-y-4"
                    >
                      <h2>{grownUp?.firstName}</h2>
                      <InputGroup
                        as="select"
                        name={`incomes.${index}.employmentStatus`}
                        label={t("Employment Status")}
                      >
                        <option value="">{t("Choose")}</option>
                        {[
                          { value: "not-employed", label: t("Not employed") },
                          { value: "employed", label: t("Employed") },
                          { value: "self-employed", label: t("Self-employed") },
                        ].map(({ value, label }) => (
                          <option
                            key={value}
                            name="employmentStatus"
                            value={value}
                          >
                            {label}
                          </option>
                        ))}
                      </InputGroup>
                      {values.incomes[index].employmentStatus !==
                        "not-employed" && (
                        <InputGroup
                          component={CurrencyField}
                          name={`incomes.${index}.gross`}
                          label={t("Gross income per year")}
                        />
                      )}
                    </div>
                  ))
                }
              />
            </div>
            <WizardFooter />
          </Form>
        )}
      </Formik>
    </>
  );
}
