import {
  doc,
  updateDoc,
  getFirestore,
  arrayRemove,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  getDoc,
} from "firebase/firestore";

export async function deleteCustomer(customerID, consultationID) {
  const db = getFirestore();
  const consultationRef = doc(db, "consultations", consultationID);
  await updateDoc(consultationRef, {
    "customers.grownUps": arrayRemove(customerID),
    "customers.children": arrayRemove(customerID),
  });
  return await deleteDoc(doc(db, "customers", customerID));
}

export async function getCustomer(currentUserID) {
  const db = getFirestore();
  const consultationsRef = collection(db, "consultations");
  const consultationsQuery = query(
    consultationsRef,
    where("user", "==", currentUserID),
  );
  const snapshot = await getDocs(consultationsQuery);
  const consultations = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));
  const lastConsultation = consultations.slice(-1)[0];
  const customerID = lastConsultation.customers.grownUps[0];
  const customerRef = doc(db, "customers", customerID);
  const customerSnapshot = await getDoc(customerRef);
  const customer = { id: customerSnapshot.id, ...customerSnapshot.data() };
  return customer;
}
