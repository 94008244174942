import { useContext } from "react";
import { CustomersContext } from "../../../../../contexts";
import Chart from "./Chart";
import ScenarioHeader from "./ScenarioHeader";
import Sidebar from "./sidebar/Sidebar";

export default function Scenario({
  data,
  customerID,
  type,
  cause,
  close,
  scenarios,
}) {
  const customers = useContext(CustomersContext);
  const customer = customers[customerID];

  if (!data) {
    return null;
  }

  const { totals, series } = data;
  const { stack, fill, events } = series || {};

  return (
    <div className="fixed bg-white z-10 top-0 bottom-0 left-0 right-0 flex flex-col ">
      <ScenarioHeader
        close={close}
        type={type}
        cause={cause}
        customer={customer}
      />
      <div className="flex flex-col lg:flex-row w-screen flex-1 min-h-0 overflow-auto">
        <Sidebar
          totals={totals}
          type={type}
          cause={cause}
          customer={customer}
          scenarios={scenarios}
          customerID={customerID}
        />
        <div className="h-full flex-1">
          <Chart
            data={
              // ["yearly", "monthly"].includes(viewType)
              "yearly" ? stack || [] : fill || []
            }
            events={events || []}
            type={type}
            cause={cause}
          />
        </div>
      </div>
    </div>
  );
}
