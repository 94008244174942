import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import Footer from "./landing/Footer";
import Header from "./landing/Header";

export default function StaticPage({ file }) {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(file)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, [file]);

  return (
    <div>
      <Header />
      <article className="prose prose-slate lg:prose-xl max-w-xl py-12 mx-auto">
        <ReactMarkdown children={markdown} />
      </article>
      <Footer />
    </div>
  );
}
