import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import useSpendingTypes from "../../../../hooks/useSpendingTypes";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { ConsultationContext, WhitelabelContext } from "../../../../contexts";
import { updateConsultation } from "../../../../database/consultations";
import Button from "../../../../components/Button";
import { MinusCircleIcon } from "@heroicons/react/solid";
import PeriodicPaymentInputGroup from "../../../../components/PeriodicPaymentInputGroup";
import StyledErrorMessage from "../../../../components/shared/fields/StyledErrorMessage";
import {
  formatAsCHF,
  getEstimatedSpending,
  getTotalFromPeriodicPayments,
} from "../../../../utils/helpers";
import { uniqueId } from "lodash";
import useCustomerInsurances from "../../../../hooks/useCustomerInsurances";
import classNames from "classnames";

export default function SpendingsForm({ customerID, close }) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const insurances = useCustomerInsurances(customerID);
  const spendingTypes = useSpendingTypes();

  const consultation = useContext(ConsultationContext);
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};

  const customerBudget = consultation.budgets?.[customerID] || {};
  const customerProvisions = consultation.provisions?.[customerID] || {};
  const { spendings = [] } = customerBudget;
  const budgetSpendings = spendings.filter(
    item =>
      item.category !== "thirdPillar" && item.category !== "lifeInsurances",
  );

  const thirdPillarCost =
    typeof customerProvisions.thirdPillar === "object"
      ? getTotalFromPeriodicPayments(
          customerProvisions.thirdPillar.map(asset => asset.deposit),
        )
      : 0;

  const insurancesCost = getTotalFromPeriodicPayments(
    insurances.map(insurance => insurance.premium),
  );

  const spendingsSchema = Yup.object().shape({
    spendings: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required(t("Required")),
        value: Yup.number().notRequired(),
      }),
    ),
  });

  const onSubmit = async values => {
    // Initialise value with 0 if user empties the value field
    const spendings = values.spendings.map(v => ({
      ...v,
      value: v.value || 0,
    }));
    const { grownUps: grownUpIDs } = consultation.customers;
    const definedSpendings = grownUpIDs.map(id => {
      const customerBudget = consultation.budgets[id];
      if (customerBudget.spendings) {
        return id === customerID
          ? consultation.budgets[customerID].spendings
          : spendings;
      } else {
        return getEstimatedSpending(customerBudget.income.gross);
      }
    });
    const requirements = definedSpendings.reduce((sum, spending) => {
      sum += parseInt(spending.value);
      return sum;
    }, 0);

    await updateConsultation(consultationID, {
      [`budgets.${customerID}.spendings`]: spendings,
      [`budgets.requirements`]: requirements,
    });
    close();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        spendingOption: "",
        requirements: 0.8,
        spendings: Object.values(...[{ ...spendingTypes, ...budgetSpendings }]), // merge spendingTypes & spendings into a simple array
      }}
      validationSchema={spendingsSchema}
      onSubmit={onSubmit}
    >
      {({ values, dirty }) => {
        return (
          <Form className="flex flex-col space-y-4">
            {thirdPillarCost > 0 && (
              <table>
                <tbody>
                  <tr>
                    <th>{t("Provisions")}</th>
                  </tr>
                  <tr className="flex justify-between">
                    <td>
                      <span className="mr-5">{t("Third Pillar")}</span>
                    </td>
                    <td>
                      {formatAsCHF(thirdPillarCost)}{" "}
                      <span className="inline-block md:ml-24 md:pr-8">
                        {t("per month")}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            <FieldArray
              name="spendings"
              render={arrayHelpers => (
                <div className="flex flex-col space-y-4">
                  <table className="w-full">
                    <tbody>
                      {values.spendings.map(({ label, category }, index) => (
                        <Fragment key={index}>
                          {index < spendingTypes.length ? (
                            <>
                              <tr>
                                <th className="text-lg font-semibold">
                                  {index >= 1
                                    ? values.spendings[index].category !==
                                        values.spendings[index - 1].category &&
                                      category
                                    : values.spendings[0].category}
                                </th>
                              </tr>
                              {index === 0 && (
                                <tr className="h-12 align-middle">
                                  <td>
                                    <span className="mr-5">
                                      {t("Life insurances")}
                                    </span>
                                  </td>
                                  <td>
                                    {insurancesCost === 0 ? (
                                      <Button
                                        type="button"
                                        to={`/consultations/${consultationID}/insurances`}
                                      >
                                        {t("Add insurance")}
                                      </Button>
                                    ) : (
                                      <span className="md:pl-3">
                                        {formatAsCHF(insurancesCost)}{" "}
                                        <span className="md:ml-24">
                                          {t("per month")}
                                        </span>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              )}
                              {index === 21 && (
                                <a
                                  href="https://swisstaxcalculator.estv.admin.ch/#/calculator/income-wealth-tax"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classNames(
                                    whiteLabeler
                                      ? `text-${name}-brand`
                                      : "text-brand",
                                    "block pt-3",
                                  )}
                                >
                                  {t("ESTV Steuerrechner")}
                                </a>
                              )}
                              <tr>
                                <td>{label}</td>
                                <td className="py-3 md:py-0">
                                  <PeriodicPaymentInputGroup
                                    name={`spendings.${index}`}
                                  />
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              {index === spendingTypes.length && (
                                <tr>
                                  <th className="text-lg font-semibold pb-4">
                                    {t("Other")}
                                  </th>
                                </tr>
                              )}
                              <tr>
                                <td className="flex items-center space-x-2">
                                  <div>
                                    <Field
                                      type="text"
                                      name={`spendings.${index}.label`}
                                      placeholder={t("Name")}
                                      defaultValue={label}
                                    />
                                    <ErrorMessage
                                      name={`spendings.${index}.label`}
                                      render={StyledErrorMessage}
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    className="text-red-500"
                                    onClick={() => arrayHelpers.remove(index)} // remove spending from the list
                                  >
                                    <MinusCircleIcon className="h-5 w-5" />
                                  </button>
                                </td>
                                <td>
                                  <PeriodicPaymentInputGroup
                                    name={`spendings.${index}`}
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                  <Button
                    secondary
                    onClick={() =>
                      arrayHelpers.push({
                        label: "",
                        interval: "year",
                        name: uniqueId("customSpending."),
                        value: "0",
                        category: t("Other"),
                      })
                    }
                  >
                    {t("Add spending")}
                  </Button>
                </div>
              )}
            />
            {dirty && (
              <Button type="submit" className="mt-5 sticky bottom-0">
                {t("Save")}
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}
