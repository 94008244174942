import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import Button from "../Button";
import Logo from "../Logo";
import { useEffect, useState } from "react";

export default function Header() {
  const location = useLocation();
  const { t } = useTranslation();

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-white backdrop-blur-sm shadow-lg"
      }`}
    >
      <div className="container mx-auto">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex-shrink-0 mr-4">
            <Link to={"/"}>
              <Logo />
            </Link>
          </div>
          <Button
            secondary
            to="/login"
            state={{ backgroundLocation: location }}
          >
            {t("Go to cockpit")}
          </Button>
        </div>
      </div>
    </header>
  );
}
