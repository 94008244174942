import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useSpendingTypes() {
  const { t } = useTranslation();
  return useMemo(
    () => [
      {
        label: t("Non-life Insurances"),
        name: "nonLifeInsurances",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Insurances"),
      },
      {
        label: t("Other Insurances"),
        name: "otherInsurances",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Insurances"),
      },
      {
        label: t("Rent"),
        name: "rent",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Housing"),
      },
      {
        label: t("Mortgage Interest Payment"),
        name: "mortgageInterest",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Housing"),
      },
      {
        label: t("Mortgage Amortization"),
        name: "mortgageAmortization",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Housing"),
      },
      {
        label: t("Running costs (Electricity, Water, Heating, Waste)"),
        name: "runningCosts",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Housing"),
      },
      {
        label: t("Maintenance Costs"),
        name: "maintenance",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Housing"),
      },
      {
        label: t("Food & Beverages"),
        name: "food",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Living"),
      },
      {
        label: t("Childcare Services"),
        name: "childcare",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Living"),
      },
      {
        label: t("Clothing & Well-being"),
        name: "clothing",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Living"),
      },
      {
        label: t("Cleaning & Various"),
        name: "cleaning",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Living"),
      },
      {
        label: t("Internet, Phone, TV, Radio, Newspapers, Subscriptions"),
        name: "communication",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Living"),
      },
      {
        label: t("Animals"),
        name: "animals",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Living"),
      },
      {
        label: t("Doctor, Dentist, Drugs, Optician"),
        name: "doctor",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Health Expenses"),
      },
      {
        label: t("Health Insurance"),
        name: "healthInsurance",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Health Expenses"),
      },
      {
        label: t("Car, Car Taxes, Maintenance, Amortization"),
        name: "car",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Mobility"),
      },
      {
        label: t("Public Transport"),
        name: "publicTransport",
        interval: "year",
        hideForSpouse: true,
        value: 0,
        category: t("Mobility"),
      },
      {
        label: t("Leisure & Various"),
        name: "various",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Mobility"),
      },
      {
        label: t("Vacation"),
        name: "vacation",
        interval: "year",
        hideForSpouse: true,
        value: 0,
        category: t("Mobility"),
      },
      {
        label: t("Sport, Hobbies, Clubs"),
        name: "hobbies",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Mobility"),
      },
      {
        label: t("Education & Culture"),
        name: "education",
        interval: "month",
        hideForSpouse: true,
        value: 0,
        category: t("Mobility"),
      },
      /*{
        label: t("Community / cantonal taxes"),
        name: "communityTaxes",
        interval: "year",
        hideForSpouse: true,
        value: 0,
        category: t("Taxes"),
      },*/
      {
        label: t("Federal taxes"),
        name: "federalTaxes",
        interval: "year",
        hideForSpouse: true,
        value: 0,
        category: t("Taxes"),
      },
    ],
    [t]
  );
}
