import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import WizardFooter from "../../../../components/shared/wizard/WizardFooter";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import { useNavigate, useParams } from "react-router-dom";
import GrownUpForm from "../../../../forms/GrownUpForm";

export default function MainPerson() {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const navigate = useNavigate();

  const customers = useContext(CustomersContext);
  const consultation = useContext(ConsultationContext);

  const { grownUps } = consultation?.customers || {};
  const thisPersonID = grownUps?.[0];
  const person = customers?.[thisPersonID] || {};

  return (
    <div className="max-w-lg mx-auto text-center flex flex-col space-y-4">
      <h1 className="text-3xl">{t("Who are you?")}</h1>
      <GrownUpForm
        grownUp={person}
        id={thisPersonID}
        onSubmit={() => {
          navigate(`/consultations/${consultationID}/wizard/partnership`);
        }}
        ActionsComponent={<WizardFooter />}
      />
    </div>
  );
}
