import { useEffect, useState } from "react";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

export default function useSubscription() {
  const db = getFirestore();
  const [subscription, setSubscription] = useState(null);
  const userID = getAuth().currentUser?.uid;

  useEffect(() => {
    if (userID) {
      const userRef = doc(db, "users", userID);
      getDoc(userRef).then((res) => setSubscription(res.data().subscription));
    }
  }, [db, userID]);
  return subscription;
}
