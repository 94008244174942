import React, { useContext } from "react";
import { FieldArray, Form, Formik } from "formik";
import Button from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import InputGroup from "../../../../components/shared/fields/InputGroup";
import { XCircleIcon } from "@heroicons/react/solid";
import { updateConsultation } from "../../../../database/consultations";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { ConsultationContext } from "../../../../contexts";
import CurrencyField from "../../../../components/CurrencyField";
import createAndSaveSolutions from "../../../../utils/createAndSaveSolutions";

export default function FreeAssetsForm({ customer, close }) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const consultation = useContext(ConsultationContext);
  let freeAssets = [];

  if (consultation.provisions) {
    if (Object.keys(consultation.provisions)[0] === customer) {
      freeAssets = Object.values(consultation.provisions)[0].freeAssets;
    } else if (Object.keys(consultation.provisions)[1] === customer) {
      freeAssets = Object.values(consultation.provisions)[1].freeAssets;
    }
  }

  const assetsSchema = Yup.object().shape({
    assets: Yup.array().of(
      Yup.object().shape({
        description: Yup.string().required(t("Required")),
        value: Yup.string().required(t("Required")),
      }),
    ),
  });

  const onSubmit = async values => {
    const { assets } = values;
    updateConsultation(consultationID, {
      [`provisions.${customer}.freeAssets`]: assets,
    });
    createAndSaveSolutions(consultationID);
    close();
  };

  return (
    <Formik
      initialValues={{
        assets: freeAssets,
      }}
      validationSchema={assetsSchema}
      onSubmit={onSubmit}
    >
      {({ values, dirty }) => (
        <Form className="space-y-2">
          <p className="text-lg text-gray-500">
            {t(
              "Add assets like savings accounts, shares, gold or cryptocurrencies.",
            )}
          </p>
          <FieldArray
            name="assets"
            render={arrayHelpers => (
              <div className="flex flex-col">
                <div className="flex flex-wrap space-y-5">
                  {values.assets &&
                    values.assets.map((asset, index) => (
                      <div
                        key={index}
                        className="relative flex space-x-2 p-4 border rounded bg-white"
                      >
                        <InputGroup
                          name={`assets.${index}.description`}
                          type="text"
                          description={t("Description")}
                        />
                        <InputGroup
                          description={t("Value")}
                          component={CurrencyField}
                          type="number"
                          name={`assets.${index}.value`}
                        />
                        <button
                          type="button"
                          className="absolute -top-3 -right-3 text-red-500"
                          onClick={() => arrayHelpers.remove(index)} // remove an asset from the list
                        >
                          <XCircleIcon className="h-6 w-6" />
                        </button>
                      </div>
                    ))}
                </div>
                <Button
                  secondary
                  onClick={() =>
                    arrayHelpers.push({
                      description: "",
                      value: "",
                    })
                  }
                  className="self-start my-2"
                >
                  {t("Add asset")}
                </Button>
                <Button disabled={!dirty} type="submit">
                  {t("Save")}
                </Button>
              </div>
            )}
          />
        </Form>
      )}
    </Formik>
  );
}
