import classNames from "classnames";
import { Link } from "react-router-dom";
import { useMatch, useResolvedPath } from "react-router-dom";
import { useContext } from "react";
import { WhitelabelContext } from "../contexts";

export default function TabLink({ path, label, Icon }) {
  let resolved = useResolvedPath(path);
  let match = useMatch({ path: resolved.pathname, end: true });
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};
  return (
    <Link
      to={path}
      className={classNames(
        "flex flex-col items-center hover:opacity-100 w-20",
        {
          [whiteLabeler ? `text-${name}-brand` : "text-brand"]: match,
          "opacity-70": !match,
        },
      )}
    >
      <Icon className="w-8 h-10" />
      <span className="text-sm hidden lg:block">{label}</span>
    </Link>
  );
}
