import React, { useContext } from "react";
import { Form, Formik } from "formik";
import Button from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import InputGroup from "../../../../components/shared/fields/InputGroup";
import { useParams } from "react-router-dom";
import { ConsultationContext } from "../../../../contexts";
import { updateConsultation } from "../../../../database/consultations";
import * as Yup from "yup";
import CurrencyField from "../../../../components/CurrencyField";

export default function SecondPillarForm({ customer, close }) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const consultation = useContext(ConsultationContext);
  let secondPillar = "";

  if (consultation.provisions) {
    if (Object.keys(consultation.provisions)[0] === customer) {
      secondPillar = Object.values(consultation.provisions)[0].secondPillar;
    } else if (Object.keys(consultation.provisions)[1] === customer) {
      secondPillar = Object.values(consultation.provisions)[1].secondPillar;
    }
  }

  const pillarSchema = Yup.object().shape({
    age: Yup.string().required(t("Required")),
    invalidity: Yup.string().required(t("Required")),
    partner: Yup.string().required(t("Required")),
    children: Yup.string().required(t("Required")),
    additionalCapital: Yup.string().required(t("Required")),
    waitingPeriod: Yup.number().required(t("Required")),
  });

  const onSubmit = async values => {
    const pillarPath = `provisions.${customer}.secondPillar`;
    const data = {
      ...values,
      hasSummary: true,
    };

    await updateConsultation(consultationID, {
      [pillarPath]: data,
    });

    close();
  };

  return (
    <Formik
      initialValues={{
        age: "",
        invalidity: "",
        partner: "",
        children: "",
        additionalCapital: "",
        waitingPeriod: 0,
        ...secondPillar,
      }}
      validationSchema={pillarSchema}
      onSubmit={onSubmit}
    >
      {({ dirty }) => (
        <Form>
          <div className="space-y-2">
            <p className="text-lg text-gray-500">
              {t(
                "You’ll find all the data to fill out the form below in your pension excerpt. You don’t have that? Request it here.",
              )}
            </p>
            <InputGroup
              component={CurrencyField}
              name="age"
              description={t("Pension")}
            />
            <InputGroup
              component={CurrencyField}
              name="invalidity"
              description={t("Invalidity Pension")}
            />
            <InputGroup
              component={CurrencyField}
              name="partner"
              description={t("Partner Pension")}
            />
            <InputGroup
              component={CurrencyField}
              name="children"
              description={t("Child Pension")}
            />
            <InputGroup
              component={CurrencyField}
              name="additionalCapital"
              description={t("Additional capital in case of death")}
            />
            <InputGroup
              as="select"
              name="waitingPeriod"
              description={t("Waiting Period")}
            >
              <option value="">---</option>
              <option value="12">12 {t("months")}</option>
              <option value="24">24 {t("months")}</option>
            </InputGroup>
            <Button disabled={!dirty} type="submit">
              {t("Save")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
