import React, { useContext } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { formatPercentage } from "../utils/helpers";
import { useTranslation } from "react-i18next";
import usePrecision from "../hooks/usePrecision";
import { Popover } from "@headlessui/react";
import colors from "tailwindcss/colors";
import MissingInformation from "./MissingInformation";
import { WhitelabelContext } from "../contexts";
import classNames from "classnames";

export default function PrecisionIndicator() {
  const { t } = useTranslation();
  const { averagePercentage } = usePrecision();
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};

  const precisions = {
    weak: {
      label: t("Weak"),
      color: colors.red[600],
    },
    moderate: {
      label: t("Moderate"),
      color: colors.orange[600],
    },
    good: {
      label: t("Good"),
      color: colors.yellow[600],
    },
    veryGood: {
      label: t("Very Good"),
      color: colors.emerald[500],
    },
    perfect: {
      label: t("Perfect"),
      color: colors.emerald[700],
    },
  };

  const precisionKey =
    averagePercentage > 0.98
      ? "perfect"
      : averagePercentage > 0.86
      ? "veryGood"
      : averagePercentage > 0.66
      ? "good"
      : averagePercentage > 0.45
      ? "moderate"
      : "weak";

  const precision = precisions[precisionKey];

  const formatPrecision = parseInt(
    parseFloat(averagePercentage * 100).toFixed(0),
  );
  const precisionData = [
    { filled: formatPrecision },
    { filled: 100 - formatPrecision },
  ];

  return (
    <Popover className="relative">
      {!isNaN(averagePercentage) && (
        <Popover.Button className={"flex items-center text-left max-w-xs"}>
          <div className="relative mr-5">
            <span
              className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-semibold text-sm"
              style={{ color: precision.color }}
            >
              {formatPercentage(averagePercentage)}
            </span>
            <PieChart width={72} height={72}>
              <Pie
                startAngle={-270}
                data={precisionData}
                dataKey="filled"
                outerRadius={36}
                innerRadius={28}
                fill="#E0E0E0"
                labelLine={false}
              >
                <Cell fill={precision.color} cornerRadius={40} />
              </Pie>
            </PieChart>
          </div>
          <div>
            <p className="font-bold text-lg">
              {t("Precision")}: {precision.label}
            </p>
            <p className="text-sm">
              {averagePercentage < 0.98 ? (
                <span>
                  <span
                    className={classNames(
                      whiteLabeler ? `text-${name}-brand` : "text-brand",
                      "font-medium",
                    )}
                  >
                    {t("Complete your profile")}
                  </span>{" "}
                  <span>{t("to improve your risk calculation")}</span>.
                </span>
              ) : (
                t(
                  "Congrats! Now we can calculate your risks with full accuracy",
                )
              )}
            </p>
          </div>
        </Popover.Button>
      )}
      <Popover.Panel className="absolute z-10 overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
        <MissingInformation />
      </Popover.Panel>
    </Popover>
  );
}
