import { useTranslation } from "react-i18next";
import Button from "./Button";

export default function DeleteButton(props) {
  const { t } = useTranslation();
  return (
    <Button type="button" className="bg-red-100 text-red-600" {...props}>
      {t("Delete")}
      {
        // TODO: Add confirmation dialog
      }
    </Button>
  );
}
