import React, { useContext } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { updateConsultation } from "../database/consultations";
import InputGroup from "../components/shared/fields/InputGroup";
import StyledForm from "../components/StyledForm";
import { deleteField } from "firebase/firestore";
import { WhitelabelContext } from "../contexts";

export default function PartnershipForm({ data, ActionsComponent }) {
  const { t } = useTranslation();
  const { consultationID } = useParams();
  const navigate = useNavigate();
  const whiteLabeler = useContext(WhitelabelContext);

  const { path = "" } = whiteLabeler || {};

  const PartnershipSchema = Yup.object().shape({
    maritalStatus: Yup.string().required(t("Required")),
    weddingDate: Yup.date().when("maritalStatus", {
      is: "not-married",
      then: Yup.date().notRequired(),
      otherwise: Yup.date().required(t("Required")),
    }),
  });

  const maritalStatusOptions = [
    { value: "not-married", label: t("No, I'm not married.") },
    { value: "married", label: t("Yes, I'm married.") },
    {
      value: "registered",
      label: t("I live in a registered partnership."),
    },
  ];

  return (
    <Formik
      initialValues={{
        ...data, // Use values from context.
      }}
      enableReinitialize
      validationSchema={PartnershipSchema}
      onSubmit={async values => {
        const data = { ...values };
        const { maritalStatus } = values;
        if (maritalStatus === "not-married") {
          delete data.weddingDate;
          await updateConsultation(consultationID, {
            weddingDate: deleteField(),
          });
        }
        await updateConsultation(consultationID, data);
        const shouldNavigate = maritalStatus !== "not-married";
        if (
          window.location.pathname ===
          `${path}/consultations/${consultationID}/wizard/partnership`
        ) {
          navigate(
            `/consultations/${consultationID}/wizard/${
              shouldNavigate ? "partner" : "children"
            }`,
          );
        }
      }}
    >
      {({ values, dirty }) => (
        <StyledForm ActionsComponent={ActionsComponent} dirty={dirty}>
          <InputGroup
            as="select"
            name="maritalStatus"
            label={t("Marital status")}
          >
            <option value="">{t("Choose")}</option>

            {maritalStatusOptions.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </InputGroup>
          {(values.maritalStatus === "married" ||
            values.maritalStatus === "registered") && (
            <InputGroup name="weddingDate" type="date" label="Wedding Date" />
          )}
        </StyledForm>
      )}
    </Formik>
  );
}
