import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Logo from "../Logo";
import { useContext } from "react";
import { WhitelabelContext } from "../../contexts";
import classNames from "classnames";

export default function Footer() {
  const { t } = useTranslation();
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};

  const footerLinks = [
    {
      path: "/about",
      label: t("About"),
    },
    {
      path: "/agb",
      label: t("AGB"),
    },
    {
      path: "/contact",
      label: t("Contact"),
    },
    {
      path: "/disclaimer",
      label: t("Disclaimer"),
    },
    {
      path: "/imprint",
      label: t("Imprint"),
    },
    {
      path: "/privacy",
      label: t("Privacy"),
    },
    {
      path: "/terms",
      label: t("Terms"),
    },
    {
      path: "/packages",
      label: t("Packages"),
    },
  ];

  return (
    <footer className="text-center bg-gray-100 flex flex-col items-center p-8 space-y-8">
      <div className="space-y-4">
        <Logo orientation={"vertical"} />
        <p className="text-sm opacity-50">
          {t("A Product of Advisory360 GmbH")}
        </p>
      </div>
      <ul className="columns-2 items-center md:flex md:space-x-3">
        {footerLinks.map((link, index) => (
          <li key={index}>
            <Link
              to={link.path}
              className={classNames(
                whiteLabeler ? `hover:text-${name}-brand` : "hover:text-brand",
                "font-medium opacity-60",
              )}
            >
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </footer>
  );
}
