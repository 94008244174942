import { getAuth, signInAnonymously } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { createConsultation } from "../database/consultations";
import Button from "./Button";
import classNames from "classnames";

export default function CreateConsultationButton({ label, smallFont }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  return (
    <Button
      className={classNames(`${smallFont ? "text-lg" : "text-2xl p-12"}`)}
      disabled={isLoading}
      onClick={async () => {
        try {
          setLoading(true);
          // Create anonymous user:
          const userCredential = await signInAnonymously(getAuth());
          // Create a new consultation:
          const consultationRef = await createConsultation(
            userCredential.user.uid,
          );
          // Navigate to the wizard:
          navigate(`/consultations/${consultationRef.id}/wizard`);
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      }}
    >
      {isLoading ? t("Preparing your cockpit...") : label}
    </Button>
  );
}
