import React, { useContext, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ClosableDialogWithTitle from "../../../../components/shared/dialogs/ClosableDialogWithTitle";
import AddButton from "../../../../components/AddButton";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import GrownUpForm from "../../../../forms/GrownUpForm";
import PersonCard from "./PersonCard";
import { deleteCustomer } from "../../../../database/customers";
import { updateConsultation } from "../../../../database/consultations";
import { useParams } from "react-router-dom";
import PartnershipForm from "../../../../forms/PartnershipForm";
import {
  collection,
  deleteDoc,
  deleteField,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import NoGrownUps from "../../../../components/NoGrownUps";
import Button from "../../../../components/Button";

export default function GrownUps() {
  const { t } = useTranslation();
  const { consultationID } = useParams();

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const { maritalStatus = "", weddingDate = "" } = consultation;

  const grownUpIDs = consultation?.customers?.grownUps || [];
  const [dialog, setDialog] = useState(null);

  const closeDialog = () => setDialog(null);

  const partnershipData = {
    maritalStatus,
    weddingDate,
  };

  const deletePerson = customerID => {
    deleteCustomer(customerID, consultationID);
    updateConsultation(consultationID, {
      maritalStatus: "not-married",
      weddingDate: deleteField(),
      [`budgets.${customerID}`]: deleteField(),
      [`provisions.${customerID}`]: deleteField(),
      [`scenarios.death.${customerID}`]: deleteField(),
      [`scenarios.invalidity.${customerID}`]: deleteField(),
    });
    const insurancesCollection = collection(
      getFirestore(),
      "consultations",
      consultationID,
      "insurances",
    );
    // Delete all customer's insurances
    const q = query(insurancesCollection, where("customer", "==", customerID));
    getDocs(q).then(snap => {
      snap.docs.map(doc => deleteDoc(doc.ref));
    });
    closeDialog();
  };

  return (
    <>
      <div className="flex">
        <h2 className="mr-4">{t("Grown Ups")}</h2>
        {grownUpIDs.length < 2 && (
          <AddButton
            onClick={() =>
              setDialog({
                title: t("Add grown up"),
                content: <GrownUpForm onSubmit={closeDialog} />,
              })
            }
          />
        )}
      </div>
      {grownUpIDs.length ? (
        <div className="md:flex space-y-3 text-center md:text-left pb-5">
          {grownUpIDs.map((customerID, index) => {
            const customer = customers[customerID];
            if (!customer) {
              return null;
            }
            return (
              <Fragment key={customerID}>
                <PersonCard
                  person={customer}
                  onClick={() =>
                    setDialog({
                      title: `${t("Edit")} ${customer.firstName}`,
                      content: (
                        <GrownUpForm
                          grownUp={customer}
                          id={customerID}
                          onSubmit={closeDialog}
                          onDelete={
                            index === 0
                              ? undefined
                              : () =>
                                  setDialog({
                                    title: t("Are you sure?"),
                                    content: (
                                      <>
                                        <p className="mb-4">
                                          {t(
                                            "Do you want to delete this person?",
                                          )}
                                        </p>
                                        <Button
                                          className="bg-red-600"
                                          onClick={() =>
                                            deletePerson(customerID)
                                          }
                                        >
                                          Yes
                                        </Button>
                                      </>
                                    ),
                                  })
                          }
                        />
                      ),
                    })
                  }
                />
                {index === 0 && (
                  <div className="mr-4 border rounded p-4">
                    <PartnershipForm data={partnershipData} />
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      ) : (
        <NoGrownUps />
      )}
      <ClosableDialogWithTitle
        title={dialog?.title}
        close={closeDialog}
        isOpen={!!dialog}
      >
        {dialog?.content}
      </ClosableDialogWithTitle>
    </>
  );
}
