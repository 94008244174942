import React, { useContext } from "react";
import { useField, Field, ErrorMessage } from "formik";
import StyledErrorMessage from "./StyledErrorMessage";
import { WhitelabelContext } from "../../../contexts";
import classNames from "classnames";

export default function InputGroup({ label, errors, description, ...props }) {
  const [field] = useField(props);
  const whiteLabeler = useContext(WhitelabelContext);

  const { name } = whiteLabeler || {};

  return (
    <label className="text-left flex flex-col space-y-1">
      {label && <p htmlFor={props.id || props.name}>{label}</p>}
      {description && <p className="opacity-50 text-sm ">{description}</p>}
      <Field
        {...field}
        {...props}
        className={classNames(
          whiteLabeler ? `focus:ring-${name}-brand` : "focus:ring-brand",
          "pr-8",
        )}
      />
      <ErrorMessage name={props.name} render={StyledErrorMessage} />
    </label>
  );
}
