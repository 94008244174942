import { useTranslation } from "react-i18next";

export default function useCompoundingRates() {
  const { t } = useTranslation();

  return [
    {
      label: t("Cash (0.3%)"),
      value: 0.003,
    },
    {
      label: t("Defensive (1%)"),
      value: 0.01,
    },
    {
      label: t("Balanced (3%)"),
      value: 0.03,
    },
    {
      label: t("Growth (5%)"),
      value: 0.05,
    },
  ];
}
