import React, { useContext, useState, Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationContext, CustomersContext } from "../../../../contexts";
import FirstPillarForm from "./FirstPillarForm";
import SecondPillarForm from "./SecondPillarForm";
import ThirdPillarForm from "./ThirdPillarForm";
import FreeAssetsForm from "./FreeAssetsForm";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid";
import Button from "../../../../components/Button";
import Card from "../../../../components/Card";
import ClosableDialogWithTitle from "../../../../components/shared/dialogs/ClosableDialogWithTitle";
import NoGrownUps from "../../../../components/NoGrownUps";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useParams } from "react-router-dom";

export default function Assets() {
  const { t } = useTranslation();
  const { consultationID } = useParams();

  const [dialog, setDialog] = useState(null);
  const [insurances, setInsurances] = useState(null);

  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  useEffect(() => {
    const insurancesCollection = collection(
      getFirestore(),
      "consultations",
      consultationID,
      "insurances",
    );
    const q = query(insurancesCollection, where("isBonded", "==", true));
    getDocs(q).then(snap =>
      setInsurances(snap.docs.map(doc => ({ id: doc.id, ...doc.data() }))),
    );
  }, [consultationID]);

  if (!consultation) {
    return null;
  }
  const grownUps = consultation.customers?.grownUps || [];

  const close = () => setDialog(null);

  const openModal = (index, customerID, insurances) => {
    setDialog(
      index === 0
        ? {
            title: t("First Pillar"),
            content: <FirstPillarForm customer={customerID} close={close} />,
          }
        : index === 1
        ? {
            title: t("Second Pillar"),
            content: <SecondPillarForm customer={customerID} close={close} />,
          }
        : index === 2
        ? {
            title: t("Third Pillar"),
            content: (
              <ThirdPillarForm
                customer={customerID}
                close={close}
                insurances={insurances}
              />
            ),
          }
        : {
            title: t("Free Assets"),
            content: <FreeAssetsForm customer={customerID} close={close} />,
          },
    );
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        <h1>{t("Assets & Provision")}</h1>
        {grownUps?.length ? (
          grownUps.map(customerID => {
            const customer = customers[customerID];
            const provision = consultation.provisions?.[customerID];
            const freeAssetsNo = provision?.freeAssets?.length;
            const insurances3a = insurances?.filter(
              i => i.customer === customerID,
            );
            const cards = [
              {
                label: t("First Pillar"),
                completed: provision?.firstPillar?.hasSummary,
              },
              {
                label: t("Second Pillar"),
                completed: provision?.secondPillar?.hasSummary,
              },
              {
                label: t("Third Pillar"),
                completed:
                  provision?.thirdPillar !== 0 || Boolean(insurances3a?.length),
              },
              {
                label: t("Free Assets"),
                completed: !!provision?.freeAssets,
              },
            ];
            return (
              <Fragment key={customerID}>
                <h2>
                  {customer?.firstName} {customer?.lastName}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                  {cards.map(({ label, completed }, index) => (
                    <Fragment key={index}>
                      <Card className="flex flex-col space-y-6 items-center font-medium">
                        <h3>{label}</h3>
                        <div>
                          {completed ? (
                            <div className="text-green-400">
                              <CheckCircleIcon className="h-14 w-14 mb-1 mx-auto" />
                              <p>{t("All details completed")}</p>
                            </div>
                          ) : (
                            <div className="text-red-400">
                              <ExclamationIcon className="h-14 w-14 mb-1 mx-auto" />
                              <p>{t("Only estimations")}</p>
                            </div>
                          )}
                        </div>
                        <Button
                          className={"w-full"}
                          secondary={completed}
                          onClick={() =>
                            openModal(index, customerID, insurances3a)
                          }
                        >
                          {completed
                            ? index !== 3
                              ? t("Edit")
                              : t("asset", {
                                  count: freeAssetsNo,
                                })
                            : t("Add details")}
                        </Button>
                      </Card>
                    </Fragment>
                  ))}
                </div>
              </Fragment>
            );
          })
        ) : (
          <NoGrownUps />
        )}
      </div>
      <ClosableDialogWithTitle
        close={close}
        isOpen={!!dialog}
        title={dialog?.title}
      >
        {dialog?.content}
      </ClosableDialogWithTitle>
    </>
  );
}
