import React, { useContext, useEffect } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  ExclamationIcon,
  ShieldCheckIcon,
  LibraryIcon,
  ScaleIcon,
  UsersIcon,
  UserCircleIcon,
} from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import TabLink from "../components/TabLink";
import { getAuth } from "firebase/auth";
import Button from "../components/Button";
import { ConsultationContext, CustomersContext } from "../contexts";
import { useIntercom } from "react-use-intercom";
import Logo from "../components/Logo";

export default function AppLayout() {
  const { t } = useTranslation();
  let location = useLocation();
  const consultation = useContext(ConsultationContext);
  const customers = useContext(CustomersContext);

  const grownUps = consultation?.customers?.grownUps || [];
  const customer = customers?.[grownUps?.[0]] || {};
  const customerID = grownUps?.[0] || "";
  const { firstName = "", lastName = "" } = customer;
  const { boot } = useIntercom();
  const { email = "" } = getAuth()?.currentUser;

  useEffect(() => {
    firstName.length &&
      boot({
        name: firstName + " " + lastName,
        user_id: customerID,
        email: email,
      });
  }, [firstName, customerID, email, lastName, boot]);

  const links = [
    {
      path: "risks",
      label: t("Risks"),
      Icon: ExclamationIcon,
    },
    {
      path: "assets",
      label: t("Assets & Provision"),
      Icon: LibraryIcon,
    },
    {
      path: "insurances",
      label: t("Insurances"),
      Icon: ShieldCheckIcon,
    },
    {
      path: "budget",
      label: t("Budget"),
      Icon: ScaleIcon,
    },
    {
      path: "people",
      label: t("People"),
      Icon: UsersIcon,
    },
  ];

  return (
    <div className="flex flex-col h-screen">
      <div className="flex justify-between p-4 border-b">
        <Link className="flex items-center space-x-2" to={"/"}>
          <Logo />
        </Link>
        <div className="flex items-center">
          {getAuth()?.currentUser.isAnonymous ? (
            <Button
              to={`/sign-up?firstName=${firstName}&lastName=${lastName}`}
              state={{ backgroundLocation: location }}
            >
              {t("Save your data")}
            </Button>
          ) : (
            <Link className="flex items-center" to={"/account"}>
              <UserCircleIcon className="w-8 h-10" />
              <span className="block">{t("Account")}</span>
            </Link>
          )}
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row flex-1 overflow-auto flex-grow">
        <div className="flex lg:flex-col justify-around p-4 text-center border-t lg:border-r lg:border-t-0 mb-14 md:mb-6 lg:mb-0">
          {links.map((link, index) => (
            <TabLink key={index} {...link} />
          ))}
        </div>
        <div className="p-6 flex-1 bg-gray-50 relative overflow-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
